import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import {
  H4,
  H5,
  Hr,
  BlankButton,
  Radio,
  Icon,
  CrossIcon,
  EditIcon,
  SelectIcon,
  MoveIcon,
  ZoomInIcon,
  ZoomOutIcon,
  PageUpIcon,
  PageDownIcon,
  FontIcon,
  OcrCheckIcon,
  OcrScoreHighIcon,
  OcrScoreMiddleIcon,
  OcrScoreLowIcon,
  PreviewIcon,
  GearsIcon
} from '../components'

const CloseButton = styled(BlankButton)`
  position: absolute;
  right: .75rem;
  color: ${({ theme }) => theme.primaryColor};

  ${Icon} {
    width: .75rem;
    height: .75rem;
  }
`

const StyledSettings = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: absolute;
  width: 350px;
  height: 100%;
  padding: .75rem .75rem .75rem 1.5rem;
  color: ${({ theme }) => theme.white};
  background-color: ${({ theme }) => theme.black};

  ${({ active }) => !active && `
    display: none;
  `}

  ${({ orientation }) => `
    top: ${orientation === 'vertical' ? '0' : '3rem'};
    left: ${orientation === 'vertical' ? '3rem' : '0'};
  `}
`

const StyledH5 = styled(H5)`
  margin-bottom: 1rem;
`

const StyledRadio = styled(Radio)`
  margin-left: 2rem;
  &:first-child { margin-left: 0; }
`

const StyledTools = styled.div`
  flex: 1;
  overflow: scroll;
`

const StyledTool = styled.div`
  display: flex;
  align-items: center;
  padding: .5rem 1rem;
  border-radius: 5px;
  color: ${({ theme }) => theme.white};
  background-color ${({ theme }) => theme.black};
  line-height: 1.5rem;
`

const ToolIcon = styled.span`
  display: inline-block;
  width: 2rem;
  height: 2rem;

  ${Icon} {
    width: 2rem;
    height: 2rem;
    color: ${({ theme }) => theme.white};
  }
`

const ToolDescription = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
`

const OCRSettings = ({
  active,
  orientation,
  onClickClose,
  onChangeOrientation,
}) => (
  <StyledSettings
    active={active}
    orientation={orientation}
  >
    <CloseButton onClick={onClickClose}><CrossIcon /></CloseButton>
    <H4>Paramètres et raccourcis</H4>
    <Hr />
    <StyledH5>Position de la barre d'outil</StyledH5>
    <div>
      <StyledRadio label="Horizontale" name="orientation" value="horizontal" onChange={onChangeOrientation} checked={orientation === 'horizontal'} />
      <StyledRadio label="Verticale" name="orientation" value="vertical" onChange={onChangeOrientation} checked={orientation === 'vertical'} />
    </div>
    <Hr />
    <StyledH5>Outils et raccourcis</StyledH5>
    <StyledTools>
      <StyledTool>
        <ToolIcon><SelectIcon /></ToolIcon>
        <ToolDescription>
          <strong>Sélectionner</strong>
          <span>(Shift + clic gauche)</span>
        </ToolDescription>
      </StyledTool>
      <StyledTool>
        <ToolIcon><MoveIcon /></ToolIcon>
        <ToolDescription>
          <strong>Naviguer</strong>
          <span>(Ctrl + clic gauche)</span>
        </ToolDescription>
      </StyledTool>
      <StyledTool>
        <ToolIcon><EditIcon /></ToolIcon>
        <ToolDescription>
          <strong>Dessiner</strong>
          <span>(clic gauche)</span>
        </ToolDescription>
      </StyledTool>
      <StyledTool>
        <ToolIcon><ZoomInIcon /></ToolIcon>
        <ToolDescription>
          <strong>Zoom +</strong>
          <span>(Molette haut)</span>
        </ToolDescription>
      </StyledTool>
      <StyledTool>
        <ToolIcon><ZoomOutIcon /></ToolIcon>
        <ToolDescription>
          <strong>Zoom -</strong>
          <span>(Molette bas)</span>
        </ToolDescription>
      </StyledTool>
      <StyledTool>
        <ToolIcon><PageUpIcon /></ToolIcon>
        <ToolDescription>
          <strong>Page précédente</strong>
        </ToolDescription>
      </StyledTool>
      <StyledTool>
        <ToolIcon><PageDownIcon /></ToolIcon>
        <ToolDescription>
          <strong>Page suivante</strong>
        </ToolDescription>
      </StyledTool>
      <StyledTool>
        <ToolIcon><FontIcon /></ToolIcon>
        <ToolDescription>
          <strong>Afficher / masquer les zones de texte</strong>
        </ToolDescription>
      </StyledTool>
      <StyledTool>
        <ToolIcon><OcrCheckIcon /></ToolIcon>
        <ToolDescription>
          <strong>Afficher / masquer les zones corrigées</strong>
        </ToolDescription>
      </StyledTool>
      <StyledTool>
        <ToolIcon><OcrScoreHighIcon /></ToolIcon>
        <ToolDescription>
          <strong>Afficher / masquer les zones océrisées avec qualité {">"} 75%</strong>
        </ToolDescription>
      </StyledTool>
      <StyledTool>
        <ToolIcon><OcrScoreMiddleIcon /></ToolIcon>
        <ToolDescription>
          <strong>Afficher / masquer les zones océrisées avec qualité entre 50% et 75%</strong>
        </ToolDescription>
      </StyledTool>
      <StyledTool>
        <ToolIcon><OcrScoreLowIcon /></ToolIcon>
        <ToolDescription>
          <strong>Afficher / masquer les zones océrisées avec qualité {"<"} 50%</strong>
        </ToolDescription>
      </StyledTool>
    </StyledTools>
  </StyledSettings>
)

OCRSettings.propTypes = {
  active: PropTypes.bool.isRequired,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']).isRequired,
  onClickClose: PropTypes.func.isRequired,
  onChangeOrientation: PropTypes.func.isRequired,
}

export default OCRSettings
