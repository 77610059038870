import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import {
  Columns,
  H4,
  FormGroup,
  Select,
  Option,
  Ul,
  BlankButton,
  Icon,
  CrossIcon,
  CloseCircleFilledIcon,
  Tag,
} from '../components'

const StyledEditor = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: absolute;
  width: 350px;
  height: 100%;
  padding: 2rem;
  box-shadow: 0px 0 10px 0px rgba(170,170,170,1);
  background-color: ${({ theme }) => theme.white};

  ${({ active }) => !active && `
    display: none;
  `}

  ${({ orientation }) => `
    top: ${orientation === 'vertical' ? '0' : '3rem'};
    right: 0;
  `}
`
const CloseButton = styled(BlankButton)`
  position: absolute;
  top: .75rem;
  right: .75rem;
  color: ${({ theme }) => theme.primaryColor};

  ${Icon} {
    width: .75rem;
    height: .75rem;
  }
`

const StyledH4 = styled(H4)`
  margin: .5rem 0;
`

const CheckAndEdit = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  height: auto;
`

const InputsWrapper = styled.div`
  flex: 1;
  overflow: hidden scroll;

  ${Ul} { margin: 0; padding: 0; }
  li { display: inline-block; }
`

const StyledInput = styled.input.attrs({
  type: 'text'
})`
  margin: .25rem;
  padding: .5rem.75rem;
  font-family: Saira;
  font-weight: normal;
  font-size: .875rem;
  border: 1px solid ${({ borderColor }) => borderColor};
  border-radius: 5px;

  &:hover, &:focus {
    border: 1px solid #7f00ff;
    outline: none;
  }
`

const Input = ({ detection, index, onChange, onMove, onClickRemove }) => {
  const { object } = detection
  const [focus, setFocus] = useState(false)
  const [value, setValue] = useState(detection.value || '')
  const [edited, setEdited] = useState(false)

  useEffect(() => () => { // cleanup
    object.set({ stroke : object._stroke, strokeWidth: 1 })
    if (object.canvas) object.canvas.requestRenderAll()
  }, [])

  const applyChange = value => {
    onChange(value.trim())
    setValue(value.trim())
  }

  const handleChange = e => {
    setValue(e.target.value)
    setEdited(true)
  }

  const handleFocus = () => {
    setFocus(true)
    object.set({ stroke : '#7f00ff', strokeWidth: 3 })
    object.canvas.requestRenderAll()
  }

  const handleBlur = () => {
    setFocus(false)
    object.set({ stroke : object._stroke, strokeWidth: 1 })
    object.canvas.requestRenderAll()

    applyChange(value)
  }

  const handleMouseEnter = () => {
    object.set({ stroke : '#7f00ff', strokeWidth: 3 })
    object.canvas.requestRenderAll()
  }

  const handleMouseLeave = () => {
    if (focus) return

    object.set({ stroke : object._stroke, strokeWidth: 1 })
    object.canvas.requestRenderAll();
  }

  const handleKeyDown = e => {
    if (e.keyCode === 13) applyChange(value)
  }

  return (
    <Tag
      index={index}
      value={value}
      onChange={handleChange}
      onMove={onMove}
      onClickRemove={onClickRemove}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onKeyDown={handleKeyDown}
    />
  )
}

Input.propTypes = {
  detection: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
}

const RemoveButton = styled(BlankButton)`
  margin-left: .5rem;
  color: red;
  align-self: center;

  ${Icon} {
    width: 1.25rem;
    height: 1.25rem;
  }
`

const ProfileNodeEditor = ({ node, profiles, onChange }) => {
  //const [profile, setProfile] = useState(profiles.find(p => p.id === node.profile))
  const [profile, setProfile] = useState(profiles.find(p => p.id === node.profile))

  const nodeFullname = (node, nodes) => {
    const parent = node.parent && nodes.find(n => n.id == node.parent)
    return (parent ? nodeFullname(parent, nodes) + ' / ' : '') + node.name
  }

  const handleChange = (newNode = { id: null }) => {
    if (newNode.id === node.id) return
    onChange(newNode)
  }

  if (profile) {
    profile.nodes = profile.nodes.filter(({ id, regex }) =>
      ( regex == null || regex == "" ? profile.nodes.every(
        ({ id: nodeId, parent }) => parent !== id || id === nodeId
      ) : false )
    )
  }

  return (
    <FormGroup>
      <Select onChange={p => setProfile(p)} placeholder="Profil d'export" dropup dropright>
        {[
          <Option key="export-profile-undefined"><i>Aucun profil</i></Option>,
          ...profiles.map(p =>
            <Option key={`export-profile-${p.id}`} value={p} selected={profile && p.id === profile.id}>
              {p.name}
            </Option>
          )
        ]}
      </Select>
      <Select onChange={handleChange} placeholder="Champ" dropup dropright>
        {[
          <Option key="export-node-undefined"><i>Champ</i></Option>,
          ...((profile && profile.nodes) || []).map(n => ( !n.hasChildren && ( typeof n.regex == "undefined" || n.regex == null || n.regex === '' ) ?
            <Option key={`export-profile-${n.id}`} value={n} selected={n.id === node.id}>
              {nodeFullname(n, profile.nodes)}
            </Option> : '')
          )
        ]}
      </Select>
      {
        node && node.id &&
        <RemoveButton onClick={e => handleChange()}>
          <CloseCircleFilledIcon />
        </RemoveButton>
      }
    </FormGroup>
  )
}

const sortByProperty =  (property) => {
  return function (x, y) {
      return ((x[property] === y[property]) ? 0 : ((x[property] > y[property]) ? 1 : -1))
  }
}

const sortByXY = (detections) => {
  var ordered = []
  var currentY = 0, indexOrdered = -1
  detections.sort(sortByProperty("y"))
  detections.map((detect, i) => {
    if (currentY == 0 || detect.y > (currentY+50)) {
      currentY = detect.y
      if (indexOrdered > -1)
        ordered[indexOrdered].sort(sortByProperty("x"))
      indexOrdered++
      ordered[indexOrdered] = []
    }
    ordered[indexOrdered].push(detect)
  })
  ordered[indexOrdered].sort(sortByProperty("x"))
  var tmpDetect = []
  ordered.map((order) => {
    tmpDetect = [].concat(tmpDetect, order)
  })
  detections = tmpDetect
  return ( detections )
}

const hasProfileNodes = currentValue => (
  currentValue 
  && currentValue.profileNodes 
  && currentValue.profileNodes.length > 0
)

const OCREditor = ({
  active,
  orientation,
  profiles,
  detections,
  onClickClose,
  onDetectionValueChange,
  onDetectionUnselect,
  onProfileNodeChange,
}) => {
  
  const [_detections, _setDetections] = useState(detections)

  const _nodes = detections[0]?.profileNodes?.filter(node =>
    node.nbDetections === detections.length &&
    detections.every(d => d.profileNodes.map(n => n.id).includes(node.id))
  ) || []

  const _node = _nodes[0] || {}

  useEffect(() => {

    const ordered = ( detections.every(hasProfileNodes) ? detections.sort((a, b) => a.profileNodes?.find(n => n.id === _node.id)?.index - b.profileNodes?.find(n => n.id === _node.id)?.index) : sortByXY(detections) )

    _setDetections(ordered)
  }, [detections])

  const swapDetection = (oldI, newI) => {
    _detections[oldI] = _detections.splice(newI, 1, _detections[oldI])[0]
    _setDetections([..._detections])

    if (_nodes[0])
      onProfileNodeChange(_nodes[0], _nodes[0], _detections)
  }

  return (
    <StyledEditor
      active={active}
      orientation={orientation}
    >
      <CloseButton onClick={onClickClose}><CrossIcon /></CloseButton>
      <CheckAndEdit>
        <StyledH4>Vérifier / Modifier</StyledH4>
        <DndProvider backend={HTML5Backend}>
          <InputsWrapper>
            {_detections.map((detection, i) => {
              return (
                <Input
                  key={detection.id || i}
                  index={i}
                  detection={detection}
                  onChange={value => onDetectionValueChange({ ...detection, value })}
                  onClickRemove={() => onDetectionUnselect(detection)}
                  onMove={swapDetection}
                />
              )
            })}
          </InputsWrapper>
        </DndProvider>
      </CheckAndEdit>
      <div>
        <StyledH4>Exporter vers</StyledH4>
        {
          _nodes.length
            ? _nodes.map(
              node =>
                <ProfileNodeEditor
                  key={`profile-node-${node.id}`}
                  node={node}
                  profiles={profiles}
                  onChange={n => onProfileNodeChange(node, n, _detections)}
                />
            ) : <ProfileNodeEditor
                node={{}}
                profiles={profiles}
                onChange={n => onProfileNodeChange(null, n, _detections)}
              />
        }
      </div>
    </StyledEditor>
  )
}

OCREditor.propTypes = {
  active: PropTypes.bool.isRequired,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']).isRequired,
  profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  detections: PropTypes.arrayOf(PropTypes.object).isRequired,
  onDetectionValueChange: PropTypes.func.isRequired,
  onProfileNodeChange: PropTypes.func.isRequired,
  onDetectionUnselect: PropTypes.func.isRequired,
  onClickClose: PropTypes.func.isRequired,
}

export default OCREditor
