import React, { useState, useEffect, forwardRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import useList from '../hooks/useList'
import { makeTree, partition } from '../../../utils'
import {
  H4,
  Form,
  FormGroup,
  TextInput,
  Radio,
  Select,
  Option,
  Button,
  BlankButton,
  Tree,
  CloseCircleFilledIcon,
} from '../components'

const StyledHeadings = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 2rem;
`

const StyledH4 = styled(H4)`
  display: inline-block;
  margin-right: 1rem;
`

const CancelButton = styled(BlankButton)`
  color: ${({ theme }) => theme.dangerRed};
  font-size: .75rem;
`

const ResetButton = styled(BlankButton)`
  color: ${({ theme }) => theme.blue};
  font-size: .75rem;
`

const NodesPreview = styled(FormGroup)`
  text-align: left;

  height: 150px;
  overflow: scroll;
  padding: 1rem;
  font-family: Saira;
  font-weight: normal;
  font-size: .875rem;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
`

const StyledUl = styled.ul`
  margin: 0;
  padding: 0 0 0 1rem;
`

const StyledNode = styled.li`
  ${({ selected }) => selected && `
    > ${BlankButton} { font-weight: bold; }
  `}
`

const NodeOptions = styled.div`
  display: inline-block;
`

const tmpId = () =>
  ('0000'+((Math.random() * (100000 - 101) + 101)|0)).slice(-5)

const exceptChildren = (parent, nodes) => {
  const children = onlyChildren(parent, nodes)
  return nodes.filter(n => !children.find(c => c.id == n.id))
}

const onlyChildren = (parent, nodes) => {
  const [_children, other] = partition(nodes, n => n.parent == parent.id)
  return [..._children, ..._children.map(child => onlyChildren(child, other))].flat(Infinity)
}

const ProfileForm = forwardRef(({ profile, onSubmit, onCancel }, ref) => {
  const [name, setName] = useState(profile.name)
  const [format, setFormat] = useState(profile.format)
  const [
    { items:nodes },
    {
      setItem:setNode,
      updateItem:updateNode,
      resetItems:resetNodes,
      deleteItem:deleteNode,
    }
  ] = useList()
  const [currentNode, setCurrentNode] = useState({})
  const [deletedNodes, setDeletedNodes] = useState([])

  useEffect(() => {
    setName(profile.name)
    setFormat(profile.format)
    resetNodes(profile.nodes || [])
    setDeletedNodes([])
    setCurrentNode({})
  }, [profile])

  const handleClickCancel = () =>
    onCancel()

  const handleClickReset = () => {
    setName(profile.name)
    setFormat(profile.format)
    resetNodes(profile.nodes)
    setDeletedNodes([])
    setCurrentNode({})
  }

  const toggleCurrentNode = node => {
    setCurrentNode(currentNode.id !== node.id ? node : {})
  }

  const handleClickAddNode = () => {
    if (!currentNode.name) return

    if (currentNode.parent && ['txt', 'csv'].includes(format))
      setFormat('xml')

    setNode({ ...currentNode, id: tmpId() })
    setCurrentNode({})
  }

  const handleClickUpdateNode = () => {
    if (!currentNode.id) return

    if (currentNode.parent && ['txt', 'csv'].includes(format))
      setFormat('xml')
    console.log(currentNode)
    updateNode(currentNode.id, currentNode)
    setCurrentNode({})
  }

  const handleClickDeleteNode = node => {
    setDeletedNodes([node.id, ...onlyChildren(node, nodes).map(n => n.id)])
    resetNodes(exceptChildren(node, nodes))
    deleteNode(node.id)
  }

  const handleSubmit = e => {
    e.preventDefault()

    onSubmit({ ...profile, name, format, nodes, _deleted: deletedNodes })
  }

  const renderNodes = _nodes => (
    <StyledUl>
      {_nodes.map(node => (
        <StyledNode
          key={`node-${node.id}`}
          selected={node.id === currentNode.id}
        >
          <BlankButton onClick={() => toggleCurrentNode(node)}>
            {node.name}
          </BlankButton>
          <NodeOptions>
            <BlankButton onClick={() => handleClickDeleteNode(node)}>
              <CloseCircleFilledIcon />
            </BlankButton>
          </NodeOptions>
          {node.children && renderNodes(node.children)}
        </StyledNode>
      ))}
    </StyledUl>
  )

  const nodeFullName = (node, nodes) => {
    const parent = node.parent && nodes.find(n => n.id == node.parent)
    return (parent ? nodeFullName(parent, nodes) + ' / ' : '') + node.name
  }

  return (
    <Form onSubmit={handleSubmit}>
      <StyledHeadings>
        <StyledH4>
          {
            profile.id
            ? 'Modifier un profil'
            : 'Créer un profil'
          }
        </StyledH4>
        {
          profile.id
          ? <CancelButton onClick={handleClickCancel}>Annuler</CancelButton>
          : <ResetButton onClick={handleClickReset}>Réinitialiser</ResetButton>
        }
      </StyledHeadings>
      <div>
        <FormGroup>
          <TextInput ref={ref}
            label="Nom du profil"
            value={name}
            onChange={setName}
            required
          />
        </FormGroup>
        <FormGroup style={{ justifyContent: 'space-between' }}>
          {['txt', 'csv'].map(_format => (
            <Radio
              key={_format}
              name="format"
              value={_format}
              label={_format.toUpperCase()}
              checked={_format === format}
              onClick={() => setFormat(_format)}
              disabled={!!profile.id || nodes.some(n => n.parent)}
            />
          ))}
          {['xml', 'json'].map(_format => (
            <Radio
              key={_format}
              name="format"
              value={_format}
              label={_format.toUpperCase()}
              checked={_format === format}
              onClick={() => setFormat(_format)}
              disabled={!!profile.id}
            />
          ))}
        </FormGroup>
        <FormGroup>
          <TextInput
            label={currentNode.id ? "Modifier champ" : "Nouveau champ"}
            value={currentNode.name}
            onChange={name => setCurrentNode({ ...currentNode, name })}
          />
        </FormGroup>
        <FormGroup>
          <TextInput
            label={currentNode.id ? "Modifier Regex" : "Nouvelle Regex"}
            value={currentNode.regex}
            onChange={regex => setCurrentNode({ ...currentNode, regex })}
          />
        </FormGroup>
        <FormGroup>
          {nodes &&
            <Select style={{ width: '100%' }}
              placeholder="Définir parent (Aucun par défaut)"
              onChange={parent => setCurrentNode({ ...currentNode, parent })}
            >
              {
                (currentNode.id
                ? exceptChildren(currentNode, nodes).filter(node => node.id != currentNode.id)
                : nodes
                ).map(node =>
                  <Option
                    key={`node-${node.id}`}
                    value={node.id}
                    selected={node.id === currentNode.parent}
                  >
                    {nodeFullName(node, nodes)}
                  </Option>
                )
              }
            </Select>
          }
        </FormGroup>
        <FormGroup>
          {
            currentNode.id
            ? (
              <Button
                disabled={!currentNode.name || !currentNode.name.length}
                primary
                onClick={handleClickUpdateNode}
                type="button"
              >
                Modifier
              </Button>
            ) : (
              <Button
                disabled={!currentNode.name || !currentNode.name.length}
                primary
                onClick={handleClickAddNode}
                type="button"
              >
                Ajouter
              </Button>
            )
          }

        </FormGroup>
        <NodesPreview>
          <Tree>{renderNodes(makeTree(nodes))}</Tree>
        </NodesPreview>
        <FormGroup>
          <Button
            primary
            disabled={!format || !name || !name.length || !nodes.length}
            type="submit"
          >
            {
              profile.id
              ? 'Modifier'
              : 'Créer'
            }
          </Button>
        </FormGroup>
      </div>
    </Form>
  )
})

ProfileForm.propTypes = {
  profile: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default ProfileForm
