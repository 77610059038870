import React from 'react'
import styled from 'styled-components'
import { useParams, useHistory } from 'react-router-dom'

import Dictionaries from './Dictionaries'
import Documents from './Documents'
import Profiles from './Profiles'
import Projects from './Projects'

import { Tabs } from '../components'

const StyledTabs = styled(Tabs)`
  width: 100%;
  height: 100%;
`

export default (props) => {
  const history = useHistory()
  const { resource } = useParams()

  return (
    <StyledTabs
      currentTab={resource}
      panes={[
        {
          key: 'projects',
          title: 'Gestion des projets',
          content: <Projects {...props} />
        },
        {
          key: 'dictionaries',
          title: 'Dictionnaires métiers',
          content: <Dictionaries {...props} />
        },
        {
          key: 'documents',
          title: 'Gestion des documents',
          content: <Documents {...props} />
        },
        {
          key: 'profiles',
          title: 'Gestion des profils',
          content: <Profiles {...props} />
        }
      ]}
      onChange={v => history.push(`/manage/${v}`)}
    />
  )
}
