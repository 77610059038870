import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const ProgressBar = styled.div`
  flex: 1;
  display: flex;
  height: .625rem;
  overflow: hidden;
  border-radius: .625rem;
  background-color: ${({ theme }) => theme.progress.backgroundColor};
`

ProgressBar.propTypes = {
  theme: PropTypes.shape({
    progress: PropTypes.shape({
      backgroundColor: PropTypes.string,
    })
  }),
}

const ProgressFill = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  transition: width .6s ease;

  border-radius: 1rem;

  width: ${({ percent }) => percent}%;
`

ProgressFill.propTypes = {
  percent: PropTypes.number,
}

const Percent = styled.span`
  display: inline-block;
  font-family: Roboto;
  font-size: .875rem;
  margin-left: .875rem;
`

const StyledProgress = styled.div`
  display: flex;
  align-items: center;

  ${({ color, theme }) => (`
    ${Percent} {
      color: ${color || theme.progress.color};
    }
    ${ProgressFill} {
      background-color: ${color || theme.progress.color};
    }
  `)}
`

StyledProgress.propTypes = {
  color: PropTypes.string,
  theme: PropTypes.shape({
    progress: PropTypes.shape({
      color: PropTypes.string,
    })
  }),
}

export const Progress = ({ min, max, value, color, showPercent, ...props }) => {
  const percent = ((value - min) * 100) / (max - min)

  return (
    <StyledProgress {...props} color={color}>
      <ProgressBar>
        <ProgressFill percent={percent} />
      </ProgressBar>
      {showPercent && <Percent value={percent}>{percent}%</Percent>}
    </StyledProgress>
  )
}

Progress.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number.isRequired,
  color: PropTypes.string,
  showPercent: PropTypes.bool,
}

Progress.defaultProps = {
  min: 0,
  max: 100,
  showPercent: false
}
