import React from 'react'
import styled from 'styled-components'

import { H1 as H1Base } from './design-system/Typography'

export { H2, H3, H4, H5, P } from './design-system/Typography'

export const H1 = styled(H1Base)`
  margin: 2rem 0 1.5rem;
`
