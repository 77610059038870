import axios from 'axios'

export const fetchDetections = pageId =>
  axios.get(`/api/pages/${pageId}/detections`).then(({ data }) => data)

export const createDetection = body =>
  axios.post('/api/detections', body).then(({ data  }) => data)

export const updateDetection = (id, body) =>
  axios.put(`/api/detections/${id}`, body).then(({ data }) => data)

export const deleteDetection = id =>
  axios.delete(`/api/detections/${id}`).then(({ data }) => data)
