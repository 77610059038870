import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'

import useList from '../hooks/useList'
import * as services from '../services'

import {
  Hr,
  H1,
  H4,
  H5,
  Vr,
  P,
  Section,
  Link,
  Form,
  Checkbox,
  Button,
  PlusIcon,
  Table,
  THead,
  TBody,
  Tr,
  Th,
  Td,
  FileDropzone,
  Select,
  Option,
  CloseCircleFilledIcon,
  SpinnerAnimatedIcon,
  BlankButton,
  ConfirmationModal,
  Icon,
  CrossIcon,
  TrashIcon,
  Row
} from '../components'

import DocumentProgress from '../modules/DocumentProgress'

const StyledContainer = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;
  height: 100%;
`

const StyledSection = styled(Section)`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  margin-bottom: ${({ addMarginBottom }) => addMarginBottom};
`

const StyledFileDropzone = styled(FileDropzone)`
  flex: 1;
  margin: 2rem;
  padding: 2rem;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
`

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: auto;
  max-width: 1024px;
`

const StyledForm = styled(Form)`
  text-align: center;
`

const StyledSelect = styled(Select)`
  margin: 0 10px;

  > button {
    padding: 0.813rem 2.5rem 0.813rem 1rem;
  }
`

const StyledH4 = styled(H4)`
  margin-bottom: 1.5rem;
`

const StyledH5 = styled(H5)`
  margin-bottom: .75rem;
`

const StyledP = styled(P)`
  margin-bottom: 1.5rem;
`

const StyledInput = styled.input`
  display: none;
`

const StyledTable = styled(Table)`
  width: 100%;
  margin-bottom: 2rem;

  a {
    color: ${({ theme }) => theme.black};

    &:hover {
      color: ${({ theme }) => theme.primaryColor};
      text-decoration: underline;
    }
  }
`

const LTd = styled(Td)`
  width: 100%;
`

const StyledLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Status = styled.span`
  font-weight: 800;
  color: ${({ theme, type }) => {
    switch (type) {
      case 'upload':
        return theme.statusColors.doing1
      case 'ocerize':
        return theme.statusColors.doing2
      case 'done':
        return theme.statusColors.done
      case 'error':
        return theme.statusColors.error
      default:
        return theme.statusColors.todo
    }
  }};
`

const DeleteButton = styled(BlankButton)`
  color: ${({ theme }) => theme.dangerRed};
`

const StyledEditor = styled.div`
  position: fixed;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 2rem;
  box-shadow: 0px -8px 10px 0px rgba(170,170,170,1);
  background-color: ${({ theme }) => theme.white};
`

const CloseButton = styled(BlankButton)`
  position: absolute;
  top: .75rem;
  right: .75rem;
  color: ${({ theme }) => theme.primaryColor};

  ${Icon} {
    width: .75rem;
    height: .75rem;
  }
`

const StyledRow = styled(Row)`
  display: flex;
`

const StyledColumn = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledInputsZone = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`

const AddLink = styled(Link)`
  display: block;
  text-align: right;
  padding-right: 10px;
  box-sizing: border-box;
`

const StyledProjectSelect = styled.div`
  display: inline-block;
`

const tmpId = () =>
  ('0000'+((Math.random() * (100000 - 101) + 101)|0)).slice(-5)

export default () => {
  const [editorHeight, setEditorHeight] = useState(0)
  const [onLoading, setOnLoading] = useState(true)
  const [_timeouts, _setTimeouts] = useState([])

  const [order, setOrder] = useState([null, 'asc'])
  const [selectedProject, setSelectedProject] = useState('later')
  const [toDeleteIds, setToDeleteIds] = useState([])
  const [
    { items:documents },
    {
      setItem:setDocument,
      setItems:setDocuments,
      updateItem:updateDocument,
      updateItems:updateDocuments,
      deleteItem:deleteDocument
    }] = useList()
  const [
    { items:requestCancelTokens },
    {
      setItem:setRequestCancelTokens,
      deleteItem:deleteRequestCancelTokens
    }] = useList()

  const ref = useRef(null)

  const selectDocuments = selected =>
    updateDocuments({ selected }, documents.filter(d => d.state != 'failed').map(d => d.id))

  const selectDocument = (id, selected) =>
    updateDocument(id, { selected })

  useEffect(() => {
    services
      .fetchAllDocuments()
      .then(({ documents }) => {
        let _documents = documents.filter(d => d.state!== 'done')

        setDocuments(_documents)
        _documents.forEach(d => timeStateFetchDocument(d.id))
        setOnLoading(false)
      })
      .catch((err) => {
        console.error(err)

        setOnLoading(false)
      })
  }, [])

  useEffect(() => () => {
    _timeouts.forEach(timeout => clearTimeout(timeout))
  }, [])

  const _clearTimeout = timeout => {
    clearTimeout(timeout)
    _setTimeouts(_timeouts.filter(t => t !== timeout))
  }

  const stateFetchDocument = (id, timeout) => {
    services
      .fetchDocument(id)
      .then(({ document }) => {
        updateDocument(id, document)

        if (document.state === 'done'
        || document.state === 'invalid'
        || document.state === 'error') return

        timeStateFetchDocument(id, timeout)
      })
      .finally(() => _clearTimeout(timeout))
  }

  const timeStateFetchDocument = (id, timeout = null) => {
    if (timeout) _clearTimeout(timeout)

    const _timeout = setTimeout(() => stateFetchDocument(id, _timeout), 2000)

    _timeouts.push(_timeout)
    _setTimeouts(_timeouts)
  }

  const upload = files => {
    for (var i = 0, len = files.length; i < len; i++) {
      let id = tmpId()
      let file = files[i]
      let document = {
        id,
        state: 'uploading',
        uploadProgress: 0,
        name: file.name.split('.').slice(0,-1).join('.') || file.name + '',
        mimetype: file.type,
        selected: false,
      }

      setDocument(document)

      const cancelToken = services.getRequestCancelToken()
      setRequestCancelTokens({ id: document.id, cancelToken })

      const _upload = (id, file) => {
        services
          .uploadFile(
            file,
            selectedProject,
            {
              onUploadProgress: uploadProgress => (
                updateDocument(id, { uploadProgress })
              ),
              cancelToken
            }
          )
          .then(data => {
            const document = data.documents[0]

            deleteRequestCancelTokens(id)

            updateDocument(id, document)
            setTimeout(() => stateFetchDocument(document.id), 2000)
          })
          .catch(({ response }) => {
            if (
              response
              && response.data.message
              && response.data.message === 'File format not supported'
            )
              updateDocument(id, { state: 'invalid' })
            else
              updateDocument(id, { state: 'error' })

            deleteRequestCancelTokens(id)
          })
      }

      _upload(id, file)
    }
  }

  const handleChange = e => {
    upload(e.target.files)

    e.target.form.reset()
  }

  const onDrop = e => {
    e.preventDefault()
    e.stopPropagation()

    upload(e.dataTransfer.files)

    return false
  }

  const onDragOver = e => {
    e.preventDefault()
    e.stopPropagation()

    return false
  }

  const [{ items: projects }, { setItems: setProjects }] = useList()

  useEffect(() => {
    services.fetchAllProjects().then(({ projects }) => setProjects(projects))
  }, [])

  const renderPlaceholder = () => {
    if (selectedProject === 'later')
      return 'Définir plus tard le projet associé'

    return (
      'Projet ' + projects.find(project => project.id === selectedProject).name
    )
  }

  const handleChangeProjectSelection = project => {
    selections.forEach(({ id }) =>
      services
        .updateDocument(id, { project })
        .then(({ document }) => updateDocument(document.id, document))
    )
  }

  const handleClickDelete = ({ id, state }) => {
    if (state === 'uploading') {
      const requestCancelToken = requestCancelTokens.find(
        request => request.id === id
      )

      requestCancelToken.cancelToken.cancel()

      deleteDocument(id)
      deleteRequestCancelTokens(id)
    } else {
      setToDeleteIds([id])
    }
  }

  const handleClickDeleteSelection = () =>
    setToDeleteIds(selections.map(({ id }) => id))

  const handleConfirmDelete = ids => {
    ids.forEach(
      id => {
        services
          .deleteDocument(id)
          .then(
            () => deleteDocument(id)
          )
          .catch(
            () => deleteDocument(id)
          )
      }
    )

    setToDeleteIds([])
  }

  const handleCancelDelete = () =>
    setToDeleteIds([])

  let selections = documents.filter(doc => doc.selected)

  const renderUploadsTable = () => (
    documents.length > 0 && (
      <StyledTable>
        <THead>
          <Tr>
            <Th>
              <Checkbox
                checked={documents.every(d => d.selected)}
                onChange={selected => selectDocuments(selected)}
              />
            </Th>
            <Th
              order={order[0] === 'name' ? order[1] : null}
              onClickOrder={() => setOrder(['name', order[1] === 'asc' ? 'desc' : 'asc'])}
            >
              Nom
            </Th>
            <Th>Projet</Th>
            <Th>Type</Th>
            <Th>État</Th>
            <Th><CloseCircleFilledIcon /></Th>
          </Tr>
        </THead>
        <TBody>
          {documents
            .sort((a, b) => (
              order[0]
              ? (order[1] === 'asc'
                ? (a[order[0]] > b[order[0]] ? -1 : 1)
                : (a[order[0]] < b[order[0]] ? -1 : 1))
              : (
                new Date(b.createdAt).getTime()
                - new Date(a.createdAt).getTime()
              )
            ))
            .map(document => (
              <Tr key={`tr-document-${document.id}`} style={{ color: document.state === 'failed' ? '#f61a1a' : 'inherit' }}>
                <Td>
                  <Checkbox
                    checked={document.selected || false}
                    onChange={selected => selectDocument(document.id, selected)}
                    disabled={document.state === 'failed'}
                  />
                </Td>
                <LTd>{document.name}</LTd>
                <Td empty={!document.project}>
                  {
                    !!document.project
                    ? <Link to={`/manage/projects?id=${document.project.id}`}>
                        {document.project.name}
                      </Link>
                    : 'Aucun'
                  }
                </Td>
                <Td>{document.ext}</Td>
                <Td>
                  {<DocumentProgress document={document} />}
                </Td>
                <Td>
                  {
                    (
                      document.state === 'uploading'
                      || document.state === 'done'
                      || document.state === 'error'
                      || document.state === 'invalid'
                    ) ? (
                      <DeleteButton
                        onClick={() => handleClickDelete(document)}
                      >
                        <CloseCircleFilledIcon />
                      </DeleteButton>
                    ) : null
                  }
                </Td>
              </Tr>
            ))
          }
        </TBody>
      </StyledTable>
    )
  )

  useEffect(() => {
    if (ref.current)
      setEditorHeight(ref.current.clientHeight)
  })

  return (
    <StyledContainer>
      <StyledSection
        addMarginBottom={selections.length > 0 ? editorHeight + 'px' : '2rem'}
      >
        <StyledFileDropzone
          onDrop={onDrop}
          onDragOver={onDragOver}
        >
          <StyledContent>
            <H1>Importer</H1>
            <StyledForm onSubmit={e => e.preventDefault()}>
              <StyledP>Importer un ou plusieurs fichiers en drag and drop ou en cliquant sur Sélectionner. Les formats supportés sont PDF, JPG, PNG ou TIFF.</StyledP>
              <StyledInput type="file" id="files" name="files" multiple onChange={handleChange} />
              <StyledInputsZone>
                <StyledProjectSelect>
                  <StyledSelect
                    placeholder={renderPlaceholder()}
                    onChange={setSelectedProject}
                  >
                    {
                      [
                        (
                          <Option key='later' value='later'>
                            Définir plus tard le projet associé
                          </Option>
                        ),
                        ...(
                          projects.map(
                            ({ id, name }) => (
                              <Option key={id} value={id}>
                                {`Projet: ${name}`}
                              </Option>
                            )
                          )
                        )
                      ]
                    }
                  </StyledSelect>
                  <AddLink to="/manage/projects">
                    + Ajouter un projet
                  </AddLink>
                </StyledProjectSelect>
                <Button
                  as="label"
                  htmlFor="files"
                  primary
                  leftIcon={<PlusIcon />}
                >
                  Sélectionner des documents
                </Button>
              </StyledInputsZone>
            </StyledForm>
            <Hr />
            <StyledH4>
              Documents en importation:&nbsp;
              {onLoading ? <SpinnerAnimatedIcon /> : documents.length}
            </StyledH4>
            <StyledP>
              L’importation se fait en tâche de fond et ne vous empêche pas de travailler sur d’autres documents. La pastille de couleur dans la barre d’outil vous permet de surveillez l’évolution du processus d'import pour chaque document :
            </StyledP>
            <StyledP>
            <Status type='upload'>• Importation en cours</Status> <Status type='ocerize'>• Océrisation en cours</Status> <Status type='done'>• Importation terminée</Status> <Status type='error'>• Importation en échec</Status>
            </StyledP>
            {
              onLoading
              ? (
                <StyledLoading>
                  <SpinnerAnimatedIcon />&nbsp;
                  Chargement ...
                </StyledLoading>
              )
              : renderUploadsTable()
            }
          </StyledContent>
        </StyledFileDropzone>
      </StyledSection>
      {selections.length > 0 &&
        <StyledEditor ref={ref}>
          <CloseButton onClick={() => selectDocuments(false)}><CrossIcon /></CloseButton>
          <StyledH4>{selections.length} Documents sélectionnés</StyledH4>
          <StyledRow>
            <StyledColumn>
              <Form>
                <StyledH5>Déplacer vers :</StyledH5>
                <Select placeholder="Déplacer vers le projet..." dropup onChange={handleChangeProjectSelection}>
                  {projects.map(({ id, name }) =>
                    <Option
                      key={`project-${id}`}
                      value={id}
                      selected={selections.every(doc => doc.project && doc.project.id === id)}
                    >
                      {`Projet: ${name}`}
                    </Option>
                  )}
                </Select>
              </Form>
            </StyledColumn>
            <Vr />
            <StyledColumn>
              <Button
                leftIcon={<TrashIcon />}
                danger
                onClick={handleClickDeleteSelection}
                disabled={
                  !selections.every(
                    selection => (
                      selection.state === 'done'
                      || selection.state === 'error'
                      || selection.state === 'invalid'
                    )
                  )
                }
              >
                Supprimer
              </Button>
            </StyledColumn>
          </StyledRow>
        </StyledEditor>
      }
      {toDeleteIds.length > 0 &&
        <ConfirmationModal isOpen danger
          title="Êtes-vous sûr de vouloir faire ça ?"
          description="Supprimer un document. Cette action est irreversible."
          onConfirm={() => handleConfirmDelete(toDeleteIds)}
          onCancel={() => handleCancelDelete()}
        />
      }

    </StyledContainer>
  )
}
