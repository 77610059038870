import axios from 'axios'

export const fetchAllWords = params =>
  axios.get('/api/words', { params }).then(({ data }) => data)

export const createWord = body =>
  axios.post('/api/words', body).then(({ data }) => data)

export const updateWord = (id, body) =>
  axios.put(`/api/words/${id}`, body).then(({ data }) => data)

export const deleteWord = id =>
  axios.delete(`/api/words/${id}`).then(({ data }) => data)
