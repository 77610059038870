import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import { fabric } from 'fabric'

import * as services from '../services'

import useList from '../hooks/useList'
import FabricContext, { FabricProvider } from '../contexts/FabricContext'

import Canvas from '../modules/OCRCanvas'
import Toolbar from '../modules/OCRToolbar'
import Explorer from '../modules/OCRExplorer'
import Settings from '../modules/OCRSettings'
import Editor from '../modules/OCREditor'

const StyledOCR = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  box-sizing: border-box;
  padding-top: 0;
  padding-left: 50px;

  ${({ orientation }) => orientation === 'horizontal' && `
    padding-top: 50px;
    padding-left: 0;
  `}

`

const OCRPage = () => {
  const [
    { canvas, canvasMode },
    { setCanvasMode, resetCanvas }
  ] = useContext(FabricContext)
  const [drawing, setDrawing] = useState(false)

  const [orientation, setOrientation] = useState('vertical')
  const [displayPanel, setDisplayPanel] = useState('explorer') // ['explorer', 'settings', null]
  const [displayZones, setDisplayZones] = useState(true)
  const [displayCorrected, setDisplayCorrected] = useState(true)
  const [displayHighScore, setDisplayHighScore] = useState(true)
  const [displayMidScore, setDisplayMidScore] = useState(true)
  const [displayLowScore, setDisplayLowScore] = useState(true)

  const [canvasLoading, setCanvasLoading] = useState(false)
  const [projectsFetching, setProjectsFetching] = useState(true)

  const [profiles, setProfiles] = useState([])
  const [{
    items:projects
  }, {
    resetItems:setProjects,
    updateItem:updateProject,
  }] = useList()

  const [currentProject, setCurrentProject] = useState(null)
  const [currentDocument, setCurrentDocument] = useState(null)
  const [currentPage, setCurrentPage] = useState(null)
  const [{
    items:detections
  }, {
    setItem:setDetection,
    resetItems:resetDetections,
    updateItem:updateDetection,
    updateItems:updateDetections,
    deleteItem:deleteDetection,
  }] = useList() // JSON Objects

  const objectToDetection = object => ({ ...object.detection, object })

  const convertCoords = ({ top, left, width, height }) => ({
    x: width < 0 ? left - Math.abs(width) : left,
    y: height < 0 ? top - Math.abs(height) : top,
    width: Math.abs(width),
    height: Math.abs(height),
  })

  useEffect(() => {
    services
      .fetchAllProfiles()
      .then(({ profiles }) => setProfiles(profiles))

    services
      .fetchOCRizedDocuments()
      .then(({ documents }) => {
        const projects = documents.reduce((projects, document) => {
          let project = document.project || {}
          projects[project.id || 'undefined'] = {
            ...project,
            documents: [...(((projects[project.id || 'undefined']) || {}).documents || []), document]
          }

          return projects
        }, { 'undefined': { id: undefined, documents: [] } })

        setProjects(Object.keys(projects).map(id => projects[id]))
        setProjectsFetching(false)
      })
  }, [])

  useEffect(() => {
    if (!canvas) return

    resetCanvas('select')
    if (currentPage && currentPage.id) {
      const top = new fabric.Point(0, 0)

      setCanvasLoading(true)

      canvas.absolutePan(top)
      canvas.setZoom(.25)
      canvas.setBackgroundImage(
        `/api/pages/${currentPage.id}.${currentPage.ext}`,
        canvas.renderAll.bind(canvas)
      )

      services
        .fetchDetections(currentPage.id)
        .then(({ detections }) => {
          resetDetections(detections)
          drawDetections(detections)
          setCanvasLoading(false)
        })
    }
  }, [canvas, currentPage])

  useEffect(() => {
    if (!canvas) return

    canvas.getObjects().forEach(object =>
      object.set({
        stroke: displayZones ? object._stroke : '#fff',
        fill: displayZones ? 'transparent' : '#fff',
        selectable: displayZones,
        evented: displayZones
      })
    )
    canvas.requestRenderAll()
  }, [canvas, displayZones])

  useEffect(() => {
    if (!canvas) return

    const settings = { selectable: true, evented: true }
    const hidden = { stroke: 'transparent', selectable: false, evented: false }

    canvas.getObjects().forEach(object => {
      if (!object.detection || !!object.detection.editedValue)
        object.set(displayCorrected ? { ...settings, stroke: '#0f0' } : hidden)
      else if (object.detection && object.detection.ocrScore >= .75)
        object.set(displayHighScore ? { ...settings, stroke: '#00f' } : hidden)
      else if (object.detection && object.detection.ocrScore >= .50 && object.detection.ocrScore < .75)
        object.set(displayMidScore ? { ...settings, stroke: '#ff8c00' } : hidden)
      else
        object.set(displayLowScore ? { ...settings, stroke: '#f00' } : hidden)
    })
    canvas.requestRenderAll()
  }, [canvas, displayCorrected, displayHighScore, displayMidScore, displayLowScore])

  const drawDetections = detections => {
    if (!canvas) return

    detections.forEach(detection => {
      let stroke

      if (!!detection.editedValue)
        stroke = '#0f0'
      else if (detection.ocrScore >= .75)
        stroke = '#00f'
      else if (detection.ocrScore >= .50 && detection.ocrScore < .75)
        stroke = '#ff8c00'
      else
        stroke = '#f00'

      canvas.add(new fabric.Rect({
        detection,
        left: detection.x,
        top: detection.y,
        width: detection.width,
        height: detection.height,
        stroke,
        _stroke: stroke,
        strokeWidth : 1,
        strokeUniform: true,
        noScaleCache: false,
        strokeUniform: true,
        fill: 'transparent',
        lockUniScaling: false,
      }))
    })
    canvas.requestRenderAll()
  }

  const handleMouseDown = function(opt) {
    const e = opt.e

    if (canvasMode === 'nav') {
      this.isDragging = true
      this.selection = false
      this.lastPosX = e.clientX
      this.lastPosY = e.clientY
    } else if (canvasMode === 'edit') {
      if (canvas.getActiveObject()) return

      setDrawing(true)

      this.x1 = opt.absolutePointer.x
      this.y1 = opt.absolutePointer.y
      const object = new fabric.Rect({
        left: this.x1,
        top: this.y1,
        width: 0,
        height: 0,
        stroke: '#0f0',
        _stroke: '#0f0',
        strokeWidth : 1,
        strokeUniform: true,
        noScaleCache: false,
        strokeUniform: true,
        fill: 'transparent',
        lockUniScaling: false,
      })

      canvas.add(object)
      canvas.requestRenderAll()
      canvas.setActiveObject(object)
    }
  }

  const handleMouseMove = function(opt) {
    if (canvasMode === 'edit' && drawing) {
      const x2 = opt.absolutePointer.x,
            y2 = opt.absolutePointer.y,
            width = x2 - this.x1,
            height = y2 - this.y1

      var object = canvas.getActiveObject()
      object.set({ width, height }).setCoords()
      canvas.requestRenderAll()
    }
  }

  const handleMouseUp = function(opt) {
    const e = opt.e

    if (canvasMode === 'edit' && drawing) {
      const x2 = opt.absolutePointer.x,
            y2 = opt.absolutePointer.y
      if (this.x1 != x2 && this.y1 != y2) {
        const width = x2 - this.x1,
              height = y2 - this.y1

        var object = canvas.getActiveObject()
        object.set({ width, height }).setCoords()

        canvas.requestRenderAll()
        handleObjectCreated(opt)
      }
      setDrawing(false)
    }
  }

  const handleObjectCreated = function(opt) {
    const object = opt.target
    const { x, y, width, height } = convertCoords(object.getBoundingRect(true))

    services
      .createDetection({
        page: currentPage.id,
        x, y, width, height
      }).then(({ detection }) => {
        object.detection = detection
        setDetection(detection)
        handleSelectionUpdated(opt)
      })
  }

  const handleObjectModified = function(opt) {
    const objects = opt.target._objects || [opt.target]

    objects.forEach(object => {
      const { x, y, width, height } = object.group ?
        (object => {
          const { tl, br } = object.aCoords
          const matrix = object.group.calcTransformMatrix()

          const { x, y } = fabric.util.transformPoint(tl, matrix)
          const { x:x2, y:y2 } = fabric.util.transformPoint(br, matrix)

          const width = x2 - x, height = y2 - y
          return { x, y, width, height }
        })(object)
        : convertCoords(object.getBoundingRect(true))

      handleDetectionCoordsChange({
        ...objectToDetection(object),
        x, y, width, height,
      })
    })
  }

  const handleObjectsDelete = objects => {
    objects.forEach(object => {
      services
        .deleteDetection(object.detection.id)
        .then(() => {
          deleteDetection(object.detection.id)
          canvas.remove(object)
        })
    })
    canvas.requestRenderAll()
  }

  const handleSelectionUpdated = function(opt) {
    const objects = (opt.target._objects || [opt.target])
                      .filter(o => o.detection && o.detection.id)
                      .sort((a, b) => a.left - b.left)

    updateDetections({ selected: false, object: null })
    objects.forEach(object => {
      updateDetection(object.detection.id, { selected: true, object })
    })
  }

  const handleSelectionCleared = function(opt) {
    updateDetections({ selected: false, object: null })
  }

  const handleKeyUp = function(e) {
    switch (e.keyCode) {
      case 46:
        const group = canvas.getActiveObject()
        handleObjectsDelete(group && group._objects || [group])
      default:
        return
    }
  }

  const handleDetectionCoordsChange = detection => {
    const { id, x, y, width, height } = detection

    services
      .updateDetection(id, { x, y, width, height })
      .then(({ detection:updates}) => updateDetection(id, updates))
  }

  const handleDetectionValueChange = detection => {
    const { id, value } = detection

    services
      .updateDetection(id, { value })
      .then(({ detection:updates, document, page }) => {
        updateDetection(id, updates)

        // Should update project... but does not re-render view...
        //
        // const project = projects.find(p => p.id === document.projectId)
        // const documents = project.documents.reduce((acc, doc) => {
        //   return [...acc, doc.id === document.id ? document : doc]
        // }, [])
        // updateProject(project.id, { ...project, documents })

        if (value) {
          detection.object.set({ stroke: '#0f0', _stroke: '#0f0' })
          canvas.requestRenderAll()
        }
      })
  }

  const handleDetectionUnselect = detection => {
    const { id } = detection

    var objects = canvas.getActiveObjects().filter(o => o.detection.id !== id)
    canvas.discardActiveObject()

    var selection = new fabric.ActiveSelection(objects, { canvas })
    canvas.setActiveObject(selection)
    canvas.requestRenderAll()

    updateDetection(id, { selected: false, object: null })
  }

  const handleProfileNodeChange = (oldProfileNode, profileNode, detections) => {
    console.log(oldProfileNode)
    console.log(profileNode)
    console.log(detections)
    services
      .upsertProfileNodeDetections({
        page: currentPage.id,
        oldProfileNode: oldProfileNode?.id,
        profileNode: profileNode.id,
        detections: detections.map((d, index) => ({ id: d.id, value: d.value, index }))
      })
      .then(({ detections }) =>
        detections.forEach(d => updateDetection(d.id, d))
      )
  }

  useEffect(() => {
    if (!canvas) return

    canvas.on('mouse:down', handleMouseDown)
    canvas.on('mouse:move', handleMouseMove)
    canvas.on('mouse:up', handleMouseUp)
    canvas.on('object:modified', handleObjectModified)
    canvas.on('selection:created', handleSelectionUpdated)
    canvas.on('selection:updated', handleSelectionUpdated)
    canvas.on('selection:cleared', handleSelectionCleared)
    window.addEventListener('keyup', handleKeyUp)

    return () => {
      canvas.off('mouse:down', handleMouseDown)
      canvas.off('mouse:move', handleMouseMove)
      canvas.off('mouse:up', handleMouseUp)
      canvas.off('object:modified', handleObjectModified)
      canvas.off('selection:created', handleSelectionUpdated)
      canvas.off('selection:updated', handleSelectionUpdated)
      canvas.off('selection:cleared', handleSelectionCleared)
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [canvas, canvasMode, drawing])

  const fitCanvas = () => {
    const top = new fabric.Point(0, 0)
    canvas.absolutePan(top)
    canvas.setZoom(.25)
  }

  const upCanvasZoom = v => {
    var zoom = canvas.getZoom()
    zoom += v
    if (zoom > 5) zoom = 5
    if (zoom < .1) zoom = .1
    const center = new fabric.Point(canvas.width / 2, canvas.height / 2)
    canvas.zoomToPoint(center, zoom)
  }

  const goToPrevPage = () => {
    if (!currentDocument) return

    const index = currentDocument.pages.findIndex(p => p.id === currentPage.id) - 1
    if (index >= 0) {
      setCurrentPage(currentDocument.pages[index])
    }
    else {
      const indexCurrentDoc = currentProject.documents.findIndex(item => item.id === currentDocument._id)
      if ((indexCurrentDoc) > 0) {
        setCurrents(currentProject, currentProject.documents[indexCurrentDoc-1], currentProject.documents[indexCurrentDoc-1].pages[0])
      }
    }
  }

  const goToNextPage = () => {
    if (!currentDocument) return

    const index = currentDocument.pages.findIndex(p => p.id === currentPage.id) + 1
    if (index < currentDocument.pages.length) {
      setCurrentPage(currentDocument.pages[index])
    }
    else {
      const indexCurrentDoc = currentProject.documents.findIndex(item => item.id === currentDocument._id)
      if ((indexCurrentDoc+1) < currentProject.documents.length) {
        setCurrents(currentProject, currentProject.documents[indexCurrentDoc+1], currentProject.documents[indexCurrentDoc+1].pages[0])
      }
    }
  }

  const setCurrents = (project, document, page) => {
    setCurrentProject(project)
    setCurrentDocument(document)
    setCurrentPage(page)
  }

  const selections = detections.filter(d => d.selected)

  return (
    <StyledOCR orientation={orientation}>
      <Canvas
        loading={canvasLoading}
        orientation={orientation}
        displayPanel={!!displayPanel}
        currentDocument={currentDocument}
        currentPage={currentPage}
      />
      <Editor
        active={selections.length > 0}
        orientation={orientation}
        profiles={profiles}
        detections={selections}
        onDetectionValueChange={handleDetectionValueChange}
        onProfileNodeChange={handleProfileNodeChange}
        onDetectionUnselect={handleDetectionUnselect}
        onClickClose={() => canvas.discardActiveObject()}
      />
      <Toolbar
        canvasMode={canvasMode}
        orientation={orientation}
        displayExplorer={displayPanel === 'explorer'}
        displaySettings={displayPanel === 'settings'}
        displayZones={displayZones}
        displayCorrected={displayCorrected}
        displayHighScore={displayHighScore}
        displayMidScore={displayMidScore}
        displayLowScore={displayLowScore}
        onClickOrientation={() => setOrientation(orientation === 'horizontal' ? 'vertical' : 'horizontal')}
        onClickExplorer={() => setDisplayPanel(displayPanel === 'explorer' ? null : 'explorer')}
        onClickSettings={() => setDisplayPanel(displayPanel === 'settings' ? null : 'settings')}
        onClickEdit={() => setCanvasMode('edit')}
        onClickSelect={() => setCanvasMode(canvasMode === 'select' ? 'edit' : 'select')}
        onClickNaviguate={() => setCanvasMode(canvasMode === 'nav' ? 'edit' : 'nav')}
        onClickFit={() => fitCanvas()}
        onClickZoomIn={() => upCanvasZoom(+0.025)}
        onClickZoomOut={() => upCanvasZoom(-0.025)}
        onClickPrevPage={() => goToPrevPage()}
        onClickNextPage={() => goToNextPage()}
        onClickDisplayZones={() => setDisplayZones(!displayZones)}
        onClickDisplayCorrected={() => setDisplayCorrected(!displayCorrected)}
        onClickDisplayHighScore={() => setDisplayHighScore(!displayHighScore)}
        onClickDisplayMidScore={() => setDisplayMidScore(!displayMidScore)}
        onClickDisplayLowScore={() => setDisplayLowScore(!displayLowScore)}
      />
      <Explorer
        loading={projectsFetching}
        active={displayPanel === 'explorer'}
        orientation={orientation}
        projects={projects}
        currentProject={currentProject}
        currentDocument={currentDocument}
        currentPage={currentPage}
        onClickPage={setCurrents}
        onClickClose={() => setDisplayPanel(null)}
      />
      <Settings
        active={displayPanel === 'settings'}
        orientation={orientation}
        onClickClose={() => setDisplayPanel(null)}
        onChangeOrientation={orientation => setOrientation(orientation)}
      />
    </StyledOCR>
  )
}

export default () => (
  <FabricProvider>
    <OCRPage />
  </FabricProvider>
)
