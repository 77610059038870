import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types';
import styled from 'styled-components'

import AuthContext from '../contexts/AuthContext'
import * as services from '../services'
import useList from '../hooks/useList'

import {
  Row,
  Columns,
  H1,
  H2,
  H5,
  H4,
  P,
  BlankButton,
  Form,
  FormGroup,
  Radio,
  Button,
  TextInput,
  Icon,
  EditIcon,
  AccountIcon,
  Ul,
  CollapsedLi,
} from '../components/design-system'

const StyledPage = styled.div`
  max-width: 960px;
  margin: auto;
`

const StyledColumns = styled(Columns)`
  margin: 0 !important;
  padding-right: 5%;
  border-right: 1px solid gray;

  & + ${Columns} {
    padding-left: 5%;
  }

  &:last-child {
    border-right: 0;
  }
`

const StyledH1 = styled(H1)`
  margin: 2rem 0px;
`

const StyledHeadings = styled.div`
  margin-bottom: 2rem;
`

const SubHeadings = styled.div`
  display: flex;
  align-items: baseline;
`

const StyledH4 = styled(H4)`
  display: inline-block;
  margin: 0 1rem 2rem 0;
`

const StyledH5 = styled(H5)`
  margin-bottom: 1rem;
  font-size: 1.125rem;
  font-family: Blinker;
  font-weight: 700;
  letter-spacing: 0;
`

const EditButton = styled(BlankButton)`
  color: ${({ theme }) => theme.primaryColor};
  font-size: .75rem;
`

const CancelButton = styled(BlankButton)`
  color: ${({ theme }) => theme.dangerRed};
  font-size: .75rem;
`

const ResetButton = styled(BlankButton)`
  color: ${({ theme }) => theme.blue};
  font-size: .75rem;
`

const ButtonsGroup = styled(FormGroup)`
  margin-left: 1rem;
`

const Infos = styled(P)`
  text-align: center;

  ${Icon} {
    color: ${({ theme }) => theme.pictonBlue};
    vertical-align: middle;
  }
`

const Description = styled(P)`
  margin-bottom: 1.5rem;
`

const StyledAccountIcon = styled(AccountIcon)`
  vertical-align: middle;
  margin-left: .5rem;
  color: #50C5F3 !important;
`

const Empty = styled.p`
  font-size: .85rem;
  font-style: italic;
`

const UserLi = ({ user, currentUser, onClickEdit }) => {
  const label = (
    <>
      {`${user.firstname} ${user.lastname}`}
      {user.leader === currentUser.id && <StyledAccountIcon />}
    </>
  )

  return (
    <CollapsedLi label={label}>
      <P>
        Email: {user.email}<br />
        Société: {user.company}<br />
        N° de société: {user.employeeId}
      </P>
      <EditButton onClick={onClickEdit}>Modifier</EditButton>
    </CollapsedLi>
  )
}

UserLi.propTypes = {
  user: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  onClickEdit: PropTypes.func.isRequired,
}

export default () => {
  const [{ user:currentUser }] = useContext(AuthContext)
  const [
    { items:users },
    {
      setItem:setUser,
      setItems:setUsers,
      updateItem:updateUser
    }
  ] = useList()

  const [toUpdate, setToUpdate] = useState({
    firstname: '',
    lastname: '',
    email: '',
    company: '',
    employeeId: '',
    type: 'employee'
  })

  useEffect(() => {
    services
      .fetchAllUsers()
      .then(({ users }) => setUsers(users))
  }, [])

  const resetToUpdate = () =>
    setToUpdate({
      firstname: '',
      lastname: '',
      email: '',
      company: '',
      employeeId: '',
      type: 'employee'
    })

  const handleClickCancel = () => resetToUpdate()
  const handleClickReset = () => resetToUpdate()

  const handleClickDelete = e => null

  const handleSubmit = e => {
    e.preventDefault()

    if (toUpdate.id)
      services
        .updateUser(toUpdate.id, toUpdate)
        .then(({ user }) => {
          updateUser(user)
          setToUpdate(user)
        })
    else
      services
        .createUser(toUpdate)
        .then(({ user }) => {
          setUser(user)
          setToUpdate(user)
        })
  }

  const employees = users.filter(user =>
    user.type === 'employee' && (currentUser.type === 'admin' || user.company === currentUser.company)
  )
  const managers = users.filter(user => user.type === 'manager')
  const admins = users.filter(user => user.type === 'admin')

  return (
    <StyledPage>
      <StyledHeadings>
        <StyledH1>Gestion des utilisateurs</StyledH1>
        <Infos>
          <b>Note :</b> Les utilisateurs suivis d'un <AccountIcon /> et en tête de liste font partie de votre équipe.
        </Infos>
      </StyledHeadings>
      <Row style={{ display: 'flex', marginBottom: '1rem' }}>
        <StyledColumns nb={5}>
          <Form onSubmit={handleSubmit}>
            <SubHeadings>
              <StyledH4>
                {(toUpdate?.id ? 'Modifier' : 'Ajouter') + ' un utilisateur'}
              </StyledH4>
              {
                toUpdate?.id
                ? <CancelButton onClick={handleClickCancel}>Annuler</CancelButton>
                : <ResetButton onClick={handleClickReset}>Réinitialiser</ResetButton>
              }
            </SubHeadings>
            <FormGroup>
              <TextInput
                buttonIcon={EditIcon}
                value={toUpdate.firstname}
                onChange={firstname => setToUpdate({ ...toUpdate, firstname })}
                label='Prénom'
              />
            </FormGroup>
            <FormGroup>
              <TextInput
                buttonIcon={EditIcon}
                value={toUpdate?.lastname}
                onChange={lastname => setToUpdate({ ...toUpdate, lastname })}
                label='Nom'
              />
            </FormGroup>
            <FormGroup>
              <TextInput
                buttonIcon={EditIcon}
                value={toUpdate?.email}
                onChange={email => setToUpdate({ ...toUpdate, email })}
                label='Email'
              />
            </FormGroup>
            <FormGroup>
              <TextInput
                buttonIcon={EditIcon}
                value={toUpdate?.company}
                onChange={company => setToUpdate({ ...toUpdate, company })}
                label='Entreprise'
              />
            </FormGroup>
            <FormGroup>
              <TextInput
                value={toUpdate?.employeeId}
                buttonIcon={EditIcon}
                onChange={employeeId => setToUpdate({ ...toUpdate, employeeId })}
                label='N° de poste'
              />
            </FormGroup>
            <FormGroup inline={false}>
              <Description>
                <b>Rôle :</b> (Description des droits pour chaque rôle) Lorem ipsum dolor
                sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                labore et dolore magna aliqua.
              </Description>
              <Radio
                name='user-type'
                label='Utilisateur'
                value='employee'
                checked={toUpdate.type === 'employee'}
                onClick={() => setToUpdate({ ...toUpdate, type: 'employee' })}
              />
              <Radio
                name='user-type'
                label='Utilisateur avec droits'
                value='manager'
                checked={toUpdate.type !== 'employee'}
                onClick={() => setToUpdate({ ...toUpdate, type: 'manager' })}
              />
            </FormGroup>
            <ButtonsGroup>
              <Button primary type="submit">Valider</Button>
              {toUpdate.id && <Button danger onClick={handleClickDelete}>Supprimer l'utilisateur</Button>}
            </ButtonsGroup>
          </Form>
        </StyledColumns>
        <StyledColumns nb={7}>
          <StyledH4>Utilisateurs référencés</StyledH4>
          <div style={{ display: 'flex' }}>
            <StyledColumns nb={6}>
              {
                currentUser.type === 'admin' &&
                <div>
                  <StyledH5>Administrateurs</StyledH5>
                  <Ul>
                    {admins.map(user =>
                      <UserLi key={user.id}
                        user={user}
                        currentUser={currentUser}
                        onClickEdit={() => setToUpdate(user)}
                      />
                    )}
                  </Ul>
                </div>
              }
              <div>
                <StyledH5>Utilisateurs avec droits</StyledH5>
                {
                  managers.length
                  ? <Ul>
                      {managers.map(user =>
                        <UserLi key={user.id}
                          user={user}
                          currentUser={currentUser}
                          onClickEdit={() => setToUpdate(user)}
                        />
                      )}
                    </Ul>
                  : <Empty>Aucun utilisateur</Empty>
                }
              </div>
            </StyledColumns>
            <StyledColumns nb={6}>
              <StyledH5>Utilisateurs</StyledH5>
              {
                employees.length
                ? <Ul>
                    {employees
                      .sort(user => user.manager === currentUser.id ? -1 : 1)
                      .map(user =>
                        <UserLi key={user.id}
                          user={user}
                          currentUser={currentUser}
                          onClickEdit={() => setToUpdate(user)}
                        />
                      )}
                  </Ul>
                : <Empty>Aucun utilisateur</Empty>
              }
            </StyledColumns>
          </div>
        </StyledColumns>
      </Row>
    </StyledPage>
  )
}
