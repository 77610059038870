import React from 'react'
import styled from 'styled-components'

import { Progress } from '../components'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledProgress = styled(Progress)`
  flex: 1;
  margin-left: 1rem;
  min-width: 150px;
`

const Status = styled.span`
  font-weight: 800;
  font-family: Roboto;
  font-size: 0.875rem;
  color: ${({ theme, type }) => {
    switch (type) {
      case 'uploading':
      case 'converting':
        return theme.statusColors.doing1
      case 'detecting':
      case 'ocrizing':
        return theme.statusColors.doing2
      case 'done':
        return theme.statusColors.done
      case 'error':
      case 'invalid':
        return theme.statusColors.error
      default:
        return theme.statusColors.todo
    }
  }};
`

export default ({ document }) => {
  switch (document.state) {
    case 'uploading':
      return (
        <Wrapper>
          <Status type={document.state}>
            1/4 : Téléchargement
          </Status>
          <StyledProgress value={document.uploadProgress} showPercent color="#ff9f00" />
        </Wrapper>
      )
    case 'converting':
      return (
        <Wrapper>
          <Status type={document.state}>
            2/4 : Conversion
          </Status>
          <StyledProgress value={document.convertProgress} showPercent color="#ff9f00" />
        </Wrapper>
      )
    case 'detecting':
      return (
        <Wrapper>
          <Status type={document.state}>
            3/4 : Detection
          </Status>
          <StyledProgress value={document.detectProgress} showPercent color="#00c7f8" />
        </Wrapper>
      )
    case 'ocrizing':
      return (
        <Wrapper>
          <Status type={document.state}>
            4/4 : Océrisation
          </Status>
          <StyledProgress value={document.ocrProgress} showPercent color="#00c7f8" />
        </Wrapper>
      )
    case 'done':
      return (
        <Wrapper>
          <Status type={document.state}>
            Terminé ! OCR : {document.ocrScore}%
          </Status>
        </Wrapper>
      )
    case 'invalid':
      return (
        <Wrapper>
          <Status type={document.state}>
            Erreur : Format de fichier non reconnu
          </Status>
        </Wrapper>
      )
    case 'error':
      return (
        <Wrapper>
          <Status type={document.state}>
            En échec
          </Status>
        </Wrapper>
      )
  }
}
