import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDrag, useDrop } from 'react-dnd'

import { BlankButton, Icon, CloseCircleFilledIcon } from './design-system'

const StyledTag = styled.div`
  display: inline-grid;
  vertical-align: top;
  align-items: center;
  position: relative;
  border: solid 1px;
  border-radius: 5px;
  padding: .1em .25em;
  margin: 5px;
  font-size: .85rem;
  cursor: move;

  ${({ stacked }) => stacked && `
    align-items: stretch;

    &::after,
    input,
    textarea {
      grid-area: 2 / 1;
    }
  `}

  &::after,
  input,
  textarea {
    cursor: move;
    width: auto;
    min-width: 1em;
    grid-area: 1 / 2;
    font: inherit;
    padding: 0.25em;
    margin: 0;
    resize: none;
    background: none;
    appearance: none;
    border: none;
  }

  &::after {
    content: attr(data-value) ' ';
    visibility: hidden;
    white-space: pre-wrap;
  }

  &:focus-within {
    border-color: blue;

    textarea:focus,
    input:focus {
      outline: none;
    }
  }
}
`

const RemoveButton = styled(BlankButton)`
  color: red;
  grid-area: 1 / 3;
  align-self: center;
`

export const Tag = ({
  index,
  value,
  onMove,
  onChange,
  onClickRemove,
  onKeyDown,
  ...props
}) => {
  const el = useRef(null)
  const [, drop] = useDrop({
    accept: 'tag',
    hover(item, monitor) {
      if (!el.current) return

      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) return

      const hoverBoundingRect = el.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.x - hoverBoundingRect.left;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return

      onMove(dragIndex, hoverIndex)
      item.index = hoverIndex
    }
  })
  const [{ opacity, isDragging }, drag] = useDrag({
    item: { type: 'tag', index, value },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.5 : 1,
      isDragging: monitor.isDragging(),
    })
  })
  const [edit, setEdit] = useState(false)

  const handleDoubleClick = e => {
    setEdit(true)
    e.target.focus()
  }

  const handleKeyDown = e => {
    if (e.keyCode === 13) setEdit(false)
    onKeyDown(e)
  }

  drag(drop(el))

  return (
    <StyledTag
      ref={el}
      data-value={value}
    >
      <input
        {...props}
        value={value}
        type="text"
        size="1"
        onChange={onChange}
        onKeyDown={handleKeyDown}
        onDoubleClick={handleDoubleClick}
        readOnly={!edit}
      />
      <RemoveButton onClick={onClickRemove}>
        <CloseCircleFilledIcon />
      </RemoveButton>
    </StyledTag>
  )
}

Tag.propTypes = {
  onClickRemove: PropTypes.func,
  onKeyDown: PropTypes.func,
}

Tag.defaultProps = {
  onClickRemove: () => null,
  onKeyDown: () => null
}
