import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Ul, Li, BlankButton } from './design-system'

const StyledAlphabet = styled(Ul)`
  display: flex;
  justify-content: space-between;

  ${Li} { display: inline-block; }
`

const StyledLetter = styled(BlankButton)`
  padding: .25rem .5rem;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 5px;

  ${({ theme, active }) => theme && active && `
    background-color: ${theme.primaryColor};
    color: ${theme.white};
  `}
`

export const Alphabet = ({ currentLetter, enabledLetters, onClick }) => (
  <StyledAlphabet>
    {'abcdefghijklmnopqrstuvwxyz'.split('').map(l =>
      <Li key={`letter-${l}`}>
        <StyledLetter
          active={currentLetter === l}
          onClick={() => onClick(l)}
          disabled={!enabledLetters.includes(l)}
        >
          {l}
        </StyledLetter>
      </Li>
    )}
  </StyledAlphabet>
)

Alphabet.propTypes = {
  onClick: PropTypes.func.isRequired,
  currentLetter: PropTypes.string,
}

Alphabet.defaultProps = {
  currentLetter: 'a'
}
