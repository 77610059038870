import React, { Children, useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Icon, AngleDownIcon } from './Icons'

const DropdownToggler = styled.button.attrs({
  type: 'button'
})`
  position: relative;
  display: inline-block;
  padding: 1rem 2.5rem 1rem 1rem;
  width: 100%;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

  font-family: Saira;
  font-weight: normal;
  font-size: .875rem;
  line-height: 1rem;
  text-align: left;
  border: 1px solid;
  border-radius: 5px;
  text-overflow: ellipsis;
  overflow: hidden;

  &:focus, &:hover {
    outline: none;
  }

  ${Icon} {
    position: absolute;
    top: 50%;
    right: 1rem;
    width: 1.25rem;
    height: 1.25rem;
    margin-top: -.625rem;
    color: ${({ theme }) => theme.primaryColor};
  }
`

DropdownToggler.propTypes = {
  theme: PropTypes.shape({
    primaryColor: PropTypes.string
  })
}

const DropdownMenu = styled.div`
  position: absolute;
  left: 0;
  z-index: 998;
  float: left;
  min-width: 100%;
  padding: .5rem 0;
  margin: .125rem 0 0;
  font-size: 1rem;
  text-align: left;
  list-style: none;
  background-clip: padding-box;
  border: 1px solid;
  border-radius: .25rem;
  box-sizing: border-box;

  display: ${({ opened }) => opened ? `block` : `none`};
`

DropdownMenu.propTypes = {
  opened: PropTypes.bool
}

export const DropdownItem = styled.button.attrs({
  type: 'button'
})`
  display: block;
  width: 100%;
  padding: .25rem 1.5rem;
  clear: both;
  white-space: nowrap;
  border: 0;
  font-family: Saira;
  font-weight: normal;
  font-size: .875rem;
  line-height: 1rem;
  text-align: left;

  &:focus {
    outline: none;
  }
`

DropdownItem.propTypes = {
  onClick: PropTypes.func
}

const StyledDropdown = styled.div`
  display: inline-block;
  position: relative;
  max-width: 100%;
  z-index: 998;

  ${({ dark, onhold }) => `
    ${DropdownToggler},
    ${DropdownItem} {
      color: ${dark ? '#fff' : '#000'};
      background-color: ${dark ? '#000' : '#fff'};
    }

    ${DropdownToggler} {
      color: ${onhold ? (dark ? '#555' : '#777') : (dark ? '#fff' : '#000')};
      border-color: ${dark ? '#fff' : '#d8d8d8'};
    }

    ${DropdownToggler}:disabled {
      color: ${dark ? '#555' : '#777'};
      background-color: ${dark ? '#000' : '#fafafa'};
    }

    ${DropdownMenu} {
      color: ${dark ? '#fff' : '#000'};
      background-color: ${dark ? '#000' : '#fff'};
      border-color: ${dark ? '#fff' : '#d8d8d8'};
    }
  `}

  ${({ dropup }) => dropup && `
    ${DropdownMenu} { bottom: 100%; }
  `}

  ${({ dropright }) => dropright && `
    ${DropdownMenu} { left: auto; right: 0; }
  `}
`

export const Dropdown = ({ toggler, children, disabled, onhold, ...props }) => {
  const [opened, setOpened] = useState(props.opened)
  const el = useRef(null)

  const toggleOpened = () =>
    setOpened(!opened)

  const handleClickOutside = e => {
    if (el.current && !el.current.contains(e.target))
      setOpened(false)
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () =>
      document.removeEventListener('click', handleClickOutside, true);
  }, [])

  const handleClick = (child, e) => {
    typeof child.props.onClick === 'function' && child.props.onClick(e)
    setOpened(false)
  }

  return (
    <StyledDropdown ref={el} onhold={onhold} {...props}>
      <DropdownToggler onClick={toggleOpened} disabled={disabled}>
        {toggler}
        <AngleDownIcon />
      </DropdownToggler>
      <DropdownMenu opened={opened}>
        {Children.map(children, (child, i) =>
          child && React.cloneElement(child, {
            key: `dropdown-${i}`,
            onClick: handleClick.bind(child, child)
          })
        )}
      </DropdownMenu>
    </StyledDropdown>
  )
}

Dropdown.propTypes = {
  toggler: PropTypes.string,
  opened: PropTypes.bool,
  children: PropTypes.node,
  disabled: PropTypes.bool
}
