import axios from 'axios'

export const fetchAllTeams = params =>
  axios.get('/api/teams', { params }).then(({ data }) => data)

export const createTeam = body =>
  axios.post('/api/teams', body).then(({ data }) => data)

export const updateTeam = (id, body) =>
  axios.put(`/api/teams/${id}`, body).then(({ data }) => data)

export const deleteTeam = id =>
  axios.delete(`/api/teams/${id}`).then(({ data }) => data)
