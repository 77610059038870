import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import strftime from 'strftime'

import useList from '../hooks/useList'
import * as services from '../services'

import { makeTree } from '../../../utils'

import {
  Button,
  H1,
  H4,
  Section,
  Hr,
  Select,
  Option,
  Table,
  THead,
  TBody,
  Tr,
  Th,
  Td,
  Checkbox,
  EyeIcon,
  P,
  Pagination,
  ArrowRightIcon,
  FormGroup,
  Tree,
  SpinnerAnimatedIcon,
  Link
} from '../components'

import QuerySearchInput from '../modules/QuerySearchInput'

const StyledPage = styled.div`
  position: relative;
  overflow: auto;
  width: 100%;
  height: 100%;
`

const StyledSection = styled(Section)`
  text-align: center;
`

const StyledH4 = styled(H4)`
  margin: 1rem 0;
`

const StyledTableSection = styled(Section)`
  margin: 1rem 0;
  padding: 0 2rem;
  margin-bottom: 2rem;
`

const StyledFilters = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`

const StyledTable = styled(Table)`
  width: 100%;
  text-align: left;

  a {
    color: ${({ theme }) => theme.black};

    &:hover {
      color: ${({ theme }) => theme.primaryColor};
      text-decoration: underline;
    }
  }
`

const LTh = styled(Th)`
  width: 100%;
`

const LTd = styled(Td)`
  width: 100%;
`

const TdEyeIcon = styled(Td)`
  padding-left: .5rem;
  padding-right: .5rem;
`

const RoundedEyeIcon = styled(EyeIcon)`
  border-radius: 50%;
  padding: .25rem;
  color: ${({ theme }) => theme.white};
  background-color: ${({ theme }) => theme.primaryColor};
`

const NodesPreview = styled(FormGroup)`
  text-align: left;

  width: 340px;
  height: 150px;
  overflow: scroll;
  padding: 1rem;
  margin: 1rem auto;
  font-family: Saira;
  font-weight: normal;
  font-size: .875rem;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
`

const StyledUl = styled.ul`
  margin: 0;
  padding: 0 0 0 1rem;
`

const SubmitButton = styled(Button)`
  display: block;
  margin: 0 auto 2rem;
`

const EmptyHelp = styled.div`
  display: flex;
  margin: 2rem 0;
  justify-content: center;
  text-align: center;
`

const attrToFr = {
  'name': 'nom',
  'projectName': 'projet',
  'ext': 'type',
  'ocrScore': 'ocr'
}

const frToAttr = {
  'nom': 'name',
  'projet': 'projectName',
  'type': 'ext',
  'ocr': 'ocrScore'
}

export default () => {
  const [_timeout, _setTimeout] = useState(null)

  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState({})
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState(null)
  const [nbItemsPerPage, setNbItemsPerPage] = useState(20)
  const [activePageIndex, setActivePageIndex] = useState(1)

  const [profile, setProfile] = useState(null)
  const [profiles, setProfiles] = useState([])
  const [
    { items:documents },
    {
      setItems:setDocuments,
      updateItem:updateDocument,
      updateItems:updateDocuments,
    }
  ] = useList()

  const selectDocuments = selected =>
    updateDocuments({ selected })

  const selectDocument = (id, selected) =>
    updateDocument(id, { selected })

  const toggleOrder = by => {
    if (orderBy === by) return setOrder(order === 'asc' ? 'desc' : 'asc')

    setOrderBy(by)
    setOrder('desc')
  }

  useEffect(() => {
    services
      .fetchAllProfiles()
      .then(({ profiles }) => setProfiles(profiles))

    services
      .fetchAllDocuments()
      .then(({ documents }) => setDocuments(documents))
  }, [])

  useEffect(() => () => {
    if (_timeout) clearTimeout(_timeout)
  }, [])

  let _documents = documents
  if (Object.keys(query).length)
    _documents = documents.filter(doc =>
      Object.keys(query).every(k =>
        doc[k] && (doc[k] + '').toLowerCase().includes(query[k].toLowerCase())
      )
    )
  let _selections = _documents.filter(doc => doc.selected)

  const handleExport = () => {
    setLoading(true)

    services
      .createExport({
        profile: profile.id,
        documents: _selections.map(doc => doc.id)
      })
      .then(({ export:e }) => statusFetchExport(e.id))
  }

  const statusFetchExport = (id, timeout) => {
    services
      .fetchExport(id)
      .then(({ export:e }) => {
        if (e.status === 'done') {
          window.open(`/api/exports/${id}/download`, '_blank')
          return setLoading(false)
        }

        timeStatusFetchExport(id, timeout)
      })
      .finally(() => clearTimeout(timeout))
  }

  const timeStatusFetchExport = (id, timeout = null) => {
    if (timeout) clearTimeout(timeout)

    const _timeout = setTimeout(() => statusFetchExport(id, _timeout), 2000)

    _setTimeout(_timeout)
  }

  const renderNodes = nodes => (
    <StyledUl>
      {nodes.map(node => (
        <li key={`node-${node.id}`}>
          {node.name+(node.regex != null && node.regex != "" ? " ("+node.regex+")" : "")}
          {node.children && renderNodes(node.children)}
        </li>
      ))}
    </StyledUl>
  )

  return (
    <>
      <StyledPage>
        <H1 style={{ margin: '2rem 0' }}>Exporter</H1>
        <StyledSection>
          <StyledH4>1/ Choix du profil d'export</StyledH4>
          <div>
            <Select
              placeholder="Choisissez un profil d&apos;export"
              onChange={profile => setProfile(profile)}
            >
              {profiles.map(p => (
                <Option key={p.id} value={p} selected={profile && profile.id === p.id}>
                  {p.name}
                </Option>
              ))}
            </Select>
            {
              profile &&
                <NodesPreview>
                  <Tree>{renderNodes(makeTree(profile.nodes))}</Tree>
                </NodesPreview>
            }
          </div>
        </StyledSection>
        <Hr />
        <StyledSection>
          <StyledH4>2/ Choix des documents</StyledH4>
          <P>
            Astuce : trier le tableau par date de modification pour afficher les derniers documents travaillés
          </P>
          <StyledTableSection>
            <StyledFilters>
              <QuerySearchInput onChange={setQuery} />
              <Select onChange={v => setNbItemsPerPage(v)}>
                <Option value={20} selected={nbItemsPerPage === 20}>Afficher 20 lignes</Option>
                <Option value={50} selected={nbItemsPerPage === 50}>Afficher 50 lignes</Option>
                <Option value={100} selected={nbItemsPerPage === 100}>Afficher 100 lignes</Option>
              </Select>
            </StyledFilters>
            <StyledTable>
              <THead>
                <Tr>
                  <Th>
                    <Checkbox
                      checked={_documents.every(d => d.selected)}
                      onChange={selected => selectDocuments(selected)}
                    />
                  </Th>
                  <Th><EyeIcon /></Th>
                  <LTh
                    onClickOrder={() => toggleOrder('name')}
                    order={orderBy === 'name' ? order : null}
                  >
                    Nom
                  </LTh>
                  <Th
                    onClickOrder={() => toggleOrder('projectName')}
                    order={orderBy === 'projectName' ? order : null}
                  >
                    Projet
                  </Th>
                  <Th
                    onClickOrder={() => toggleOrder('ext')}
                    order={orderBy === 'ext' ? order : null}
                  >
                    Type
                  </Th>
                  <Th
                    onClickOrder={() => toggleOrder('createdAt')}
                    order={orderBy === 'createdAt' ? order : null}
                  >
                    Ajouté le
                  </Th>
                  <Th
                    onClickOrder={() => toggleOrder('updatedAt')}
                    order={orderBy === 'updatedAt' ? order : null}
                  >
                    Modifié le
                  </Th>
                  <Th
                    onClickOrder={() => toggleOrder('ocrScore')}
                    order={orderBy === 'ocrScore' ? order : null}
                  >
                    % OCR
                  </Th>
                </Tr>
              </THead>
              <TBody>
                {_documents.sort(
                  (a, b) => (
                    orderBy
                    ? (order === 'asc'
                      ? (a[orderBy] > b[orderBy] ? -1 : 1)
                      : (a[orderBy] < b[orderBy] ? -1 : 1))
                    : 0
                  )).map(document => (
                  <Tr key={document.id}>
                    <Td>
                      <Checkbox
                        checked={document.selected || false}
                        onChange={selected => selectDocument(document.id, selected)}
                      />
                    </Td>
                    <TdEyeIcon>
                      <a href={document.path} target="_blank"><RoundedEyeIcon /></a>
                    </TdEyeIcon>
                    <Td>{document.name}</Td>
                    <LTd empty={!document.project}>
                      {
                        !!document.project
                        ? <Link to={`/manage/projects?id=${document.project.id}`}>
                            {document.project.name}
                          </Link>
                        : 'Aucun'
                      }
                    </LTd>
                    <Td>{document.ext}</Td>
                    <Td>{strftime('%d/%m/%Y', new Date(document.createdAt))}</Td>
                    <Td>{strftime('%d/%m/%Y', new Date(document.updatedAt))}</Td>
                    <Td>{document.ocrScore}%</Td>
                  </Tr>
                  ))}
              </TBody>
            </StyledTable>
            {
              !documents.length &&
              <EmptyHelp>
                <P>
                  Il semble que vous n'ayez aucun document.<br />
                  Vous pouvez importer vos documents <Link to="/import">ici</Link>.
                </P>
              </EmptyHelp>
            }
            <Pagination
              nbItemsPerPage={nbItemsPerPage}
              nbPagesDisplayed={5}
              activePageIndex={activePageIndex}
              totalItems={_documents.length}
              onChange={i => setActivePageIndex(i)}
            />
          </StyledTableSection>
        </StyledSection>
        <SubmitButton
          primary
          leftIcon={loading ? <SpinnerAnimatedIcon /> : <ArrowRightIcon />}
          onClick={handleExport}
          disabled={!_selections.length || !profile}
        >
          Lancer l’export
        </SubmitButton>
      </StyledPage>
    </>
  )
}
