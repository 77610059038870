import axios from 'axios'

export const fetchAllProfiles = params =>
  axios.get('/api/profiles', { params }).then(({ data }) => data)

export const fetchProfile = id =>
  axios.get(`/api/profiles/${id}`).then(({ data }) => data)

export const createProfile = body =>
  axios.post('/api/profiles', body).then(({ data }) => data)

export const updateProfile = (id, body) =>
  axios.put(`/api/profiles/${id}`, body).then(({ data }) => data)

export const deleteProfile = id =>
  axios.delete(`/api/profiles/${id}`).then(({ data }) => data)
