import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'

import AuthContext from '../contexts/AuthContext'

export default ({ component: Component, ...props }) => {
  const [{ loading, authenticated }] = useContext(AuthContext)

  return (
    <Route {...props}
      render={() => (
        !loading ? (
          authenticated
          ? <Component {...props} />
          : <Redirect to={props.redirectTo ? props.redirectTo : '/login'} />
        )
        : null
      )}
    />
  )
}
