import axios from 'axios'

export const fetchAllDictionaries = params =>
  axios.get('/api/dictionaries', { params }).then(({ data }) => data)

export const createDictionary = body =>
  axios.post('/api/dictionaries', body).then(({ data }) => data)

export const updateDictionary = (id, body) =>
  axios.put(`/api/dictionaries/${id}`, body).then(({ data }) => data)

export const deleteDictionary = id =>
  axios.delete(`/api/dictionaries/${id}`).then(({ data }) => data)
