import React from 'react'
import styled from 'styled-components'
import { getAssetsRootURL } from '../../../utils'

import {
  Section,
  H1,
  H2,
  Link
} from '../components'

const HelpContainer = styled(Section)`
  padding: 0 4%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`

const StyledVideo = styled.video`
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
`

const StyledH1 = styled(H1)`
  letter-spacing: .563rem;
  text-transform: uppercase;
  font-size: 2.25rem;
  margin: 2rem 0;
  color: white;
  font-weight: normal;
`

const StyledH2 = styled(H2)`
  color: white;
`

const ExternalLink = styled.a`
  letter-spacing: .0625rem;
  margin: 0;
  font-size: 1.875rem;
  font-family: Saira;
  font-weight: 300;
  line-height: 1.25;
  color: ${({ theme }) => theme.primaryColor};

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

const StyledLink = styled(Link)`
  margin-top: 1rem;

  &:hover {
    text-decoration: underline;
  }
`

export default () => (
  <>
    <StyledVideo
      autoPlay
      loop
      muted
      id='background-video'
      poster={getAssetsRootURL(true) + '/images/video-poster.jpg'}
    >
      <source
        src={getAssetsRootURL(true) + '/videos/NeurOCR.mp4'}
        type='video/mp4'
      />
    </StyledVideo>
    <HelpContainer>
      <StyledH1>Besoin d'aide?</StyledH1>
      <StyledH2>
        Connectez-vous sur
        {' '}
        <ExternalLink href='https://support.neurocr.ai/' target='_blank'>
          https://support.neurocr.ai/
        </ExternalLink>
      </StyledH2>
      <StyledLink to='/'>Retour à l'accueil</StyledLink>
    </HelpContainer>
  </>
)
