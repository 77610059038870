import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import qs from 'qs'

import {
  SearchInput
} from '../components'

const StyledSearchInput = styled(SearchInput)`
  width: 300px;
  text-align: left;
`

const StyledHelp = styled.span`
  display: block;
  margin-top: .5rem;
  font-size: .75rem;
  font-style: italic;
  color: ${({ theme }) => theme.alto};
  text-align: left;
`

const attrToFr = {
  'id': 'id',
  'name': 'nom',
  'projectName': 'projet',
  'projectId': 'projet-id',
  'ext': 'type',
  'ocrScore': 'ocr'
}

const frToAttr = {
  'id': 'id',
  'nom': 'name',
  'projet': 'projectName',
  'projet-id': 'projectId',
  'type': 'ext',
  'ocr': 'ocrScore'
}

const QuerySearchInput = ({ help, onChange, ...props }) => {
  const location = useLocation()
  const [query,] = useState(qs.parse(location.search.replace(/^\?/, '')))

  useEffect(() => {
    onChange(query)
  }, [query])

  const queryToStr = query =>
    Object.keys(query).map(k => `${attrToFr[k]}:${query[k]}`).join(' ')

  const strToQuery = str => {
    const query = {}

    str.split(' ').forEach(_str => {
      let [k, v] = _str.split(':')
      if (!k) return

      [k, v] = [frToAttr[k] || 'name', v || k]
      query[k] = v || ''
    })

    return query
  }

  return (
    <div>
      <StyledSearchInput
        placeholder="Rechercher"
        defaultValue={queryToStr(query)}
        onChange={str => onChange(strToQuery(str))}
        {...props}
      />
      {help &&
        <StyledHelp>{help}</StyledHelp>
      }
    </div>
  )
}

QuerySearchInput.propTypes = {
  help: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func.isRequired,
}

QuerySearchInput.defaultProps = {
  help: '(nom:FR_143xx projet:DOSS_3xx type:pdf ...)',
}

export default QuerySearchInput
