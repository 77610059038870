import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import qs from 'qs'

import useList from '../hooks/useList'
import * as services from '../services'

import {
  Section,
  H1,
  P,
  Form,
  SearchInput,
  TextInput,
  Select,
  Option,
  AddCircleFilledIcon,
  Pagination,
  ConfirmationModal,
  BlankButton,
  Icon,
  CheckIcon,
  CloseIcon,
  CloseCircleFilledIcon,
  EditIcon,
  TeamIcon,
  AccountIcon,
  Ul,
  Li,
  SpinnerAnimatedIcon
} from '../components'

// Source: https://emailregex.com/
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const StyledLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledTeam = styled.div`
  width: 33.33%;
  padding: 1rem;
  box-sizing: border-box;
`

const StyledForm = styled.form`
  display: flex;
  align-items: center;
`

const StyledName = styled.strong`
  flex: 1;
  display: inline-flex;
  align-items: center;
  overflow: auto;
`

const StyledInput = styled.input`
  width: 100%;
  padding-left: 0;
  border: 0;
  line-height: 1.5;
  border-bottom: 1px dashed ${({ theme, readOnly }) => !readOnly ? theme.gray : 'transparent' };

  font-family: Blinker;
  font-size: 1.5rem;
  font-weight: bold;
  text-overflow: ellipsis;

  &:focus { outline: 0; }
`

const TeamIconWrapper = styled.span`
  line-height: 0;
  width: 1.8rem;
  height: 1.8rem;
  margin-right: .5rem;
`

const StyledTeamIcon = styled(TeamIcon)`
  width: 1.8rem;
  height: 1.8rem;
`

const StyledInfos = styled.div`
  font-size: .75rem;
  color: ${({ theme }) => theme.dustyGray};
`

const StyledButton = styled(BlankButton)`
  display: inline-flex;
  color: ${({ theme, primary, danger }) =>
    (primary && theme.primaryColor) ||
    (danger && theme.dangerRed)
  };

  ${Icon} {
    width: 1.25rem;
    height: 1.25rem;
    justify-content: center;
    align-items: center;
  }
`

const AddLink = styled.button`
  display: block;
  padding: 0;
  color: ${({ theme }) => theme.primaryColor};
  border: 0;
  background-color: #fff;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  &:focus { outline: none; }
`

const AddUserZone = styled.div`
  margin: 0.5rem 0;
`

const StyledUl = styled(Ul)`
  border-left: 1px solid grey;
`

const StyledLi = styled(Li)`
  color: #000;
  font-size: 1rem;
  display: flex;
  justify-content: start;
  align-items: center;
`

const AccountIconWrapper = styled(AccountIcon)`
  margin: 0 0.5rem;
`

const DeleteTeammateWrapper = styled.div`
  margin-left: auto;
`

const Error = styled.div`
  display: block;
  color: ${({ theme }) => theme.dangerColor};
  font-size: 0.75rem;
`

const Team = ({ team, onClickDelete }) => {
  const [onEditTeamName, setOnEditTeamName] = useState(false)
  const [editTeamNameError, setEditTeamNameError] = useState(false)

  const [openAddTeamUser, setOpenAddTeamUser] = useState(false)
  const [onAddTeamUser, setOnAddTeamUser] = useState(false)
  const [addTeamUserError, setAddTeamUserError] = useState('')
  const [name, setName] = useState(team.name)
  const [newTeamUser, setNewTeamUser] = useState('')
  const [editing, setEditing] = useState(false)
  const [toDeleteTeammate, setToDeleteTeammate] = useState(null)
  const [
    { items:teammates },
    {
      setItems:setTeammates,
      setItem:setTeammate,
      deleteItem:deleteTeammate,
    }
  ] = useList()

  const formRef = useRef(null)

  useEffect(() => {
    setTeammates(team.teammates)
  }, [])

  const handleCancelEdit = e => {
    setName(team.name)
    setEditing(false)
  }

  const handleChange = e =>
    setName(e.target.value)

  const handleAddTeammate = e => {
    e.preventDefault()

    if (!newTeamUser.length || !EMAIL_REGEX.test(newTeamUser)) return

    setOnAddTeamUser(true)

    services
      .fetchUserByEmail(newTeamUser)
      .then(({ users }) => {
        if (users.length > 0)
          services
            .updateTeam(
              team.id,
              {
                ...team,
                teammates: [
                  users[0].id,
                  ...teammates.map(t => t.id)
                ]
              }
            )
            .then(() => {
              setTeammate(users[0])
              setNewTeamUser('')
              setOnAddTeamUser(false)
              setAddTeamUserError('')
            })
            .catch(err => {
              console.error(err)

              if (err.response.status === 500)
                setAddTeamUserError('Erreur API')
              else if (err.response.status === 412)
                setAddTeamUserError("Opération invalid")
              else if (err.response.status === 409)
                setAddTeamUserError("Utilisateur déja dans l'équipe")

              setOnAddTeamUser(false)
            })
      })
      .catch(err => {
        console.error(err)

        if (err.response.status === 500)
          setAddTeamUserError('Erreur API')
        else if (err.response.status === 412)
          setAddTeamUserError("Email invalid")
        else if (err.response.status === 404)
          setAddTeamUserError("Utilisateur introuvable")

        setOnAddTeamUser(false)
      })
  }

  const handleUpdateTeamName = e => {
    e.preventDefault()

    setEditing(false)

    if (!name.length) {
      setName(team.name)
      return
    }

    setOnEditTeamName(true)

    services
      .updateTeam(team.id, { ...team, name })
      .then(() => {
        setName(name)
        setOnEditTeamName(false)
      })
      .catch(err => {
        console.error(err)

        if (err.response.status === 500)
          setEditTeamNameError('Erreur API')
        else if (err.response.status === 409)
          setEditTeamNameError("Nom d'équipe existant (" + name + ")")
        else if (err.response.status === 412)
          setEditTeamNameError("Nom d'équipe invalid")
        
        setName(team.name)
        setOnEditTeamName(false)
      })
  }

  const handleDeleteTeammate = (teammate) => {
    setToDeleteTeammate(teammate)
  }

  const handleConfirmDeleteTeammate = teammate => {
    services
      .updateTeam(
        team.id,
        {
          ...team,
          teammates: teammates.filter(
            _teammate => _teammate._id !== teammate.id
          )
        }
      )
      .then(() => deleteTeammate(teammate._id))

    setToDeleteTeammate(null)
  }

  const handleCancelDeleteTeammate = () =>
    setToDeleteTeammate(null)

  return (
    <StyledTeam>
      <StyledForm as={editing ? 'form' : 'div'} onSubmit={handleUpdateTeamName}>
        <StyledName>
          <TeamIconWrapper>
            <StyledTeamIcon />
          </TeamIconWrapper>
          <StyledInput
            type="text"
            value={name}
            onChange={e => {
              handleChange(e)
              setEditTeamNameError('')
            }}
            autoFocus={editing}
            readOnly={!editing}
            disabled={onEditTeamName}
          />
        </StyledName>
        {
          editing
          ? <>
              <StyledButton primary type="submit">
                <CheckIcon />
              </StyledButton>
              <StyledButton danger onClick={handleCancelEdit}>
                <CloseIcon />
              </StyledButton>
            </>
          : <>
              <StyledButton primary onClick={() => {
                setEditing(true)
                setEditTeamNameError('')
              }}>
                <EditIcon />
              </StyledButton>
              <StyledButton danger onClick={onClickDelete}>
                <CloseCircleFilledIcon />
              </StyledButton>
            </>
        }
      </StyledForm>
      {
        !!editTeamNameError && (
          <Error>{editTeamNameError}</Error>
        )
      }
      <StyledInfos>
        {teammates.length} collaborateurs
        <AddUserZone>
          {
            openAddTeamUser
            ? (
              <Form onSubmit={handleAddTeammate}>
                <TextInput ref={formRef}
                  name="username"
                  value={newTeamUser}
                  placeholder="Ajouter un collaborateur via son email"
                  buttonIcon={
                    onAddTeamUser ? SpinnerAnimatedIcon : AddCircleFilledIcon
                  }
                  onChange={e => {
                    setAddTeamUserError('')
                    setNewTeamUser(e)
                  }}
                  onClick={handleAddTeammate}
                  disabled={onAddTeamUser}
                  validator={v => !EMAIL_REGEX.test(v) ? 'Non valide' : null}
                />
                {
                  !!addTeamUserError && (
                    <Error>{addTeamUserError}</Error>
                  )
                }
              </Form>
            )
            : (
              <AddLink onClick={setOpenAddTeamUser}>
                + Ajouter un collaborateur
              </AddLink>
            )
          }
        </AddUserZone>
        <StyledUl>
        {
          teammates.map(
            teammate => (
              <StyledLi key={teammate.id}>
                <AccountIconWrapper>
                  <AccountIcon />
                </AccountIconWrapper>
                {teammate.email}
                <DeleteTeammateWrapper>
                  <StyledButton
                    danger
                    onClick={
                      () => handleDeleteTeammate(teammate)
                    }
                  >
                    <CloseCircleFilledIcon />
                  </StyledButton>
                </DeleteTeammateWrapper>
              </StyledLi>
            )
          )
        }
        </StyledUl>
      </StyledInfos>
      {toDeleteTeammate &&
        <ConfirmationModal isOpen danger
          title="Êtes-vous sûr de vouloir faire ça ?"
          description={`Supprimer l'utilisateur ${toDeleteTeammate.email} de l'équipe ${team.name}. Cette action est irreversible.`}
          onConfirm={() => handleConfirmDeleteTeammate(toDeleteTeammate)}
          onCancel={() => handleCancelDeleteTeammate()}
        />
      }
    </StyledTeam>
  )
}

Team.propTypes = {
  team: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
}

const StyledSection = styled(Section)`
  padding: 0 4%;
  flex: 1;
`

const StyledFilters = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`

const StyledSearchInput = styled(SearchInput)`
  width: 300px;
  margin-right: 1rem;
`

const EmptyHelp = styled.div`
  display: flex;
  margin: 2rem 0;
  justify-content: center;
  text-align: center;
`

const Teams = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export default () => {
  const nbItemsPerPage = 12
  const location = useLocation()
  const [query, setQuery] = useState(qs.parse(location.search.replace(/^\?/, '')))
  const [onLoading, setOnLoading] = useState(true)
  const [order, setOrder] = useState(['createdAt', 'asc'])
  const [activePageIndex, setActivePageIndex] = useState(1)
  const [onCreateTeam, setOnCreateTeam] = useState(false)
  const [createTeamError, setCreateTeamError] = useState('')
  const [newTeamName, setNewTeamName] = useState('')
  const formRef = useRef(null)
  const [toDeleteTeam, setToDeleteTeam] = useState(null)
  const [
    { items: teams },
    {
      setItem:setTeam,
      setItems:setTeams,
      updateItem:updateTeam,
      deleteItem:deleteTeam,
    }
  ] = useList()

  const handleCreateTeam = e => {
    e.preventDefault()

    setCreateTeamError('')

    if (!newTeamName) return

    setOnCreateTeam(true)

    services
      .createTeam({ name: newTeamName })
      .then(({ team }) => {
        setTeam(team)
        setNewTeamName('')
        setOnCreateTeam(false)
      })
      .catch(err => {
        console.error(err)

        if (err.response.status === 409)
          setCreateTeamError("L'équipe existe déja")
        else if (err.response.status === 500)
          setCreateTeamError('Erreur API')
        else if (err.response.status === 412)
          setCreateTeamError("Nom d'équipe invalid")

        setOnCreateTeam(false)
      })
  }

  const handleClickDeleteTeam = team => setToDeleteTeam(team)

  const handleConfirmDeleteTeam = id => {
    services.deleteTeam(id).then(() => deleteTeam(id))
    setToDeleteTeam(null)
  }

  const handleCancelDeleteTeam = () =>
    setToDeleteTeam(null)

  useEffect(() => {
    services
      .fetchAllTeams()
      .then(({ teams }) => {
        setTeams(teams)
        setOnLoading(false)
      })
      .catch(err => {
        console.error(err)

        setOnLoading(false)
      })
  }, [])

  let _teams = teams
  if (query.name)
    _teams = teams.filter(team =>
      team.name.toLowerCase().includes(query.name.toLowerCase())
    )

  const offset = (activePageIndex-1) * nbItemsPerPage

  return (
    <>
      <H1 style={{ margin: '2rem 0' }}>Gérer les équipes</H1>
      <StyledSection>
        <StyledFilters>
          <div>
            <StyledSearchInput
              placeholder="Rechercher"
              value={query.name || ''}
              onChange={name => setQuery({ name })}
            />
            <Select onChange={order => setOrder(order)}>
              <Option value={['name', 'desc']} selected={['name', 'desc'].every((v, i) => v === order[i])}>{'Nom DESC (A -> Z)'}</Option>
              <Option value={['name', 'asc']} selected={['name', 'asc'].every((v, i) => v === order[i])}>{'Nom ASC (Z -> A)'}</Option>
              <Option value={['createdAt', 'asc']} selected={['createdAt', 'asc'].every((v, i) => v === order[i])}>{'Date de création (Récent -> Ancien)'}</Option>
              <Option value={['createdAt', 'desc']} selected={['createdAt', 'desc'].every((v, i) => v === order[i])}>{'Date de création (Ancien -> Récent)'}</Option>
            </Select>
          </div>
          <Form onSubmit={handleCreateTeam}>
            <TextInput ref={formRef}
              name="name"
              value={newTeamName}
              placeholder="Créer une équipe"
              buttonIcon={
                onCreateTeam ? SpinnerAnimatedIcon : AddCircleFilledIcon
              }
              onChange={e => {
                setNewTeamName(e)
                setCreateTeamError('')
              }
            }
              onClick={handleCreateTeam}
              disabled={onCreateTeam}
            />
            {
              !!createTeamError && (
                <Error>{createTeamError}</Error>
              )
            }
          </Form>
        </StyledFilters>
        {
          !onLoading && !teams.length &&
            <EmptyHelp>
              <P>
                Il semble que vous n'ayez aucune équipe.<br />
                Vous pouvez <StyledButton primary onClick={() => formRef.current.focus()}>ajouter une équipe</StyledButton>.
              </P>
            </EmptyHelp>
        }
        {
          onLoading
          ? (
            <StyledLoading>
              <SpinnerAnimatedIcon />&nbsp;
              Chargement ...
            </StyledLoading>
          )
          : (
            <>
              <Teams>
                {
                  _teams
                    .sort((a, b) => (
                      order[1] === 'asc'
                      ? (a[order[0]] > b[order[0]] ? -1 : 1)
                      : (a[order[0]] < b[order[0]] ? -1 : 1)
                    ))
                    .slice(offset, offset + nbItemsPerPage)
                    .map(team =>
                      <Team
                        key={`team-${team.id}`}
                        team={team}
                        onChange={updates => handleUpdate(team.id, updates)}
                        onClickDelete={() => handleClickDeleteTeam(team)}
                      />
                    )
                }
              </Teams>
              <Pagination
                nbItemsPerPage={nbItemsPerPage}
                nbPagesDisplayed={5}
                activePageIndex={activePageIndex}
                totalItems={_teams.length}
                onChange={i => setActivePageIndex(i)}
              />
            </>
          )
        }
      </StyledSection>

      {toDeleteTeam &&
        <ConfirmationModal isOpen danger
          title="Êtes-vous sûr de vouloir faire ça ?"
          description={`Supprimer l'équipe ${toDeleteTeam.name}. Cette action est irreversible.`}
          onConfirm={() => handleConfirmDeleteTeam(toDeleteTeam.id)}
          onCancel={() => handleCancelDeleteTeam()}
        />
      }
    </>
  )
}
