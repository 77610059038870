import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import * as services from '../services'
import useList from '../hooks/useList'

import {
  Section,
  Columns,
  H1,
  P,
  Link,
  BlankButton,
  Ul,
  CollapsedLi,
  Icon,
  FolderIcon,
  PlusIcon,
  CheckIcon,
  CloseIcon,
  CloseCircleFilledIcon,
  EditIcon,
  AddCircleFilledIcon,
  SpinnerAnimatedIcon,
  BookIcon,
  Alphabet,
  Form,
  TextInput,
  Pagination,
  ConfirmationModal,
} from '../components'

import QuerySearchInput from '../modules/QuerySearchInput'

const StyledPage = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const StyledSection = styled(Section)`
  flex: 1;
  padding: 0 4%;
`

const StyledColumns = styled(Columns)`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Projects = styled.div`
  margin: 2rem .5rem 1rem;
  flex: 1;
`

const ProjectName = styled.span`
  display: inline-flex;
  align-items: center;
  font-size: 1.25rem;

  > strong {
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

const ProjectInfos = styled.span`
  font-size: .75rem;
  color: ${({ theme }) => theme.dustyGray};
`

const ProjectIconWrapper = styled.span`
  line-height: 0;
  margin-right: .5rem;

  &, ${Icon} {
    width: 1.25rem;
    height: 1.25rem;
  }
`

const AddDictionaryButton = styled(BlankButton)`
  color: ${({ theme }) => theme.primaryColor};
  margin-right: .4rem;
`

const StyledDictionaryLi = styled.li`
  font-family: Saira;
  display: flex;
  justify-content: space-between;

  ${Icon} { margin-right: .25rem; }

  margin: .5rem 0;
`

const ProjectLabel = ({ project, dictionaries, onClickAdd }) =>
  <>
    <ProjectName>
      <ProjectIconWrapper><FolderIcon /></ProjectIconWrapper>
      <strong>{project.name}</strong>
      <AddDictionaryButton onClick={onClickAdd}>
        <PlusIcon />
      </AddDictionaryButton>
    </ProjectName>
    <ProjectInfos>{dictionaries.length} dictionnaires associés</ProjectInfos>
  </>

const DictionaryLi = ({ dictionary, onClick, onClickDelete }) =>
  <StyledDictionaryLi>
    <BlankButton onClick={onClick}>
      <BookIcon /> {dictionary.name}
    </BlankButton>
    <StyledButton danger onClick={onClickDelete}>
      <CloseCircleFilledIcon />
    </StyledButton>
  </StyledDictionaryLi>

const ProjectLi = ({
  project,
  dictionaries,
  onClickAddDictionary,
  onClickDictionary,
  onClickDeleteDictionary,
}) => {
  const [collapsed, setHidden] = useState(true)

  const handleClickAddDictionary = e => {
    e.stopPropagation()
    onClickAddDictionary(project)
  }

  const handleClickDictionary = (e, dictionary) => {
    e.stopPropagation()
    onClickDictionary(dictionary)
  }

  const handleClickDeleteDictionary = (e, dictionary) => {
    e.stopPropagation()
    onClickDeleteDictionary(dictionary)
  }

  const label =
    <ProjectLabel
      project={project}
      dictionaries={dictionaries}
      onClickAdd={e => {
        setHidden(false)
        handleClickAddDictionary(e)
      }}
    />

  return (
    <CollapsedLi
      key={`project-${project.id}`}
      label={label}
      collapsed={collapsed}
      onClick={() => setHidden(!collapsed)}
    >
      <Ul>
        {
          dictionaries
            .filter(dictionary => dictionary.projects.includes(project.id))
            .map(dictionary =>
              <DictionaryLi
                key={`dictionary-${dictionary.id}`}
                dictionary={dictionary}
                onClick={e => handleClickDictionary(e, dictionary)}
                onClickDelete={e => handleClickDeleteDictionary(e, dictionary)}
              />
            )
        }
      </Ul>
    </CollapsedLi>
  )
}

const StyledOptions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`

const StyledDictionary = styled.div`
  display: inline-block;
  width: 50%;
  box-sizing: border-box;
`

const StyledForm = styled.form`
  display: flex;
  align-items: center;
`

const StyledName = styled.strong`
  flex: 1;
  display: inline-flex;
  align-items: center;
`

const StyledInput = styled.input`
  width: 100%;
  padding-left: 0;
  border: 0;
  line-height: 1.5;
  border-bottom: 1px dashed ${({ theme, readOnly }) => !readOnly ? theme.gray : 'transparent' };

  font-family: Blinker;
  font-size: 1.5rem;
  font-weight: bold;
  text-overflow: ellipsis;

  &:focus { outline: 0; }
`

const BookIconWrapper = styled.span`
  line-height: 0;
  width: 1rem;
  height: 1rem;
  margin-right: .5rem;
`

const StyledButton = styled(BlankButton)`
  color: ${({ theme, primary, danger }) =>
    (primary && theme.primaryColor) ||
    (danger && theme.dangerRed)
  };

  ${Icon} {
    width: 1.25rem;
    height: 1.25rem;
  }
`

const DictionaryForm = ({ dictionary, onChange }) => {
  const [name, setName] = useState(dictionary.name)
  const [editing, setEditing] = useState(false)

  useEffect(() => {
    setName(dictionary.name)
  }, [dictionary])

  const handleCancelEdit = e => {
    setName(dictionary.name)
    setEditing(false)
  }

  const handleChange = e =>
    setName(e.target.value)

  const handleSubmit = e => {
    e.preventDefault()
    onChange({ ...dictionary, name })
    setEditing(false)
  }

  return (
    <StyledDictionary>
      <StyledForm as={editing ? 'form' : 'div'} onSubmit={handleSubmit}>
        <StyledName>
          <BookIconWrapper>
            <BookIcon />
          </BookIconWrapper>
          <StyledInput type="text" value={name} onChange={handleChange} autoFocus={editing} readOnly={!editing} />
        </StyledName>
        {
          editing
          ? <>
              <StyledButton primary type="submit">
                <CheckIcon />
              </StyledButton>
              <StyledButton danger onClick={handleCancelEdit}>
                <CloseIcon />
              </StyledButton>
            </>
          : <StyledButton primary onClick={() => setEditing(true)}>
              <EditIcon />
            </StyledButton>
        }
      </StyledForm>
    </StyledDictionary>
  )
}

const StyledWordsUl = styled(Ul)`
  margin: 1rem 0;
`

const StyledWordLi = styled(CollapsedLi)`
  margin-bottom: .5rem;

  @media (min-width: 768px) {
    width: 33.33%;
    display: inline-block;
    vertical-align: top;
  }
`

const StyledWordEditButton = styled(StyledButton)`
  font-size: .875rem;

  ${Icon} { width: .875rem; height: .875rem; }

  padding: .2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledWordDescForm = styled(StyledForm)`
  flex-direction: column;
  align-items: flex-start;
`

const StyledWordDescription = styled(P)`
  font-size: .875rem;
  resize: none;
  outline: none;
  margin: .5rem 0;
`

const StyledLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const WordLi = ({ word, onChange, onClickDelete }) => {
  const [description, setDescription] = useState(word.description)
  const [editing, setEditing] = useState(false)

  const handleCancelEdit = e => {
    setDescription(word.description)
    setEditing(false)
  }

  const handleChange = e =>
    setDescription(e.target.value)

  const handleSubmit = e => {
    e.preventDefault()
    onChange({ ...word, description })
    setEditing(false)
  }

  return (
    <StyledWordLi label={word.value}>
      {
        editing
        ? <StyledWordDescForm onSubmit={handleSubmit}>
            <StyledWordDescription as="textarea" value={description} onChange={handleChange} autoFocus rows={3} />
            <StyledWordEditButton primary type="submit">
              <CheckIcon /> Enregistrer
            </StyledWordEditButton>
            <StyledWordEditButton danger onClick={handleCancelEdit}>
              <CloseIcon /> Annuler
            </StyledWordEditButton>
          </StyledWordDescForm>
        :
          <>
            <StyledWordDescription>{description}</StyledWordDescription>
            <StyledWordEditButton primary onClick={() => setEditing(true)}>
              <EditIcon /> Description
            </StyledWordEditButton>
            <StyledWordEditButton danger onClick={onClickDelete}>
              <CloseCircleFilledIcon /> Supprimer
            </StyledWordEditButton>
          </>
      }
    </StyledWordLi>
  )
}

export default () => {
  const nbItemsPerPage = 30
  const [onLoadingProjects, setOnLoadingProjects] = useState(true)
  const [onLoadingDictionary, setOnLoadingDictionary] = useState(true)
  const [query, setQuery] = useState({})
  const [activePageIndex, setActivePageIndex] = useState(1)
  const [currentLetter, setCurrentLetter] = useState(null)
  const [
    { items:projects },
    { resetItems:resetProjects }
  ] = useList()
  const [toDeleteId, setToDeleteId] = useState(null)
  const [currentDictionary, setCurrentDictionary] = useState(null)
  const [
    { items:dictionaries },
    {
      resetItems:resetDictionaries,
      setItem:setDictionary,
      updateItem:updateDictionary,
      deleteItem:deleteDictionary,
    }
  ] = useList()
  const [newWordValue, setNewWordValue] = useState('')
  const [
    { items:words },
    {
      resetItems:resetWords,
      setItem:setWord,
      updateItem:updateWord,
      deleteItem:deleteWord,
    }
  ] = useList()

  useEffect(() => {
    Promise.all([
      services.fetchAllProjects(),
      services.fetchAllDictionaries()
    ]).then(values => {
      resetProjects(values[0].projects.map(p => ({ ...p, projectId: p.id })))
      resetDictionaries(values[1].dictionaries)
      setOnLoadingProjects(false)
    })
  }, [])

  useEffect(() => {
    setOnLoadingDictionary(true)

    if (!currentDictionary) {
      setOnLoadingDictionary(false)
      return
    }

    services
      .fetchAllWords({ dictionary: currentDictionary.id })
      .then(({ words }) => {
        resetWords(words)
        setActivePageIndex(1)
        setOnLoadingDictionary(false)
      })
  }, [currentDictionary])

  const handleClickAddDictionary = project => {
    services
      .createDictionary({
        name: 'Nouveau dictionnaire',
        projects: [project.id]
      })
      .then(({ dictionary }) => setDictionary(dictionary))
  }

  const handleClickDictionary = dictionary => {
    setCurrentDictionary(dictionary)
    setCurrentLetter(null)
  }

  const handleClickDeleteDictionary = dictionary =>
    setToDeleteId(dictionary.id)

  const handleConfirmDelete = id => {
    services.deleteDictionary(id).then(() => {
      setCurrentLetter(null)
      setCurrentDictionary(null)
      deleteDictionary(id)
      setToDeleteId(null)
      resetWords([])
    })
  }

  const handleCancelDelete = () =>
    setToDeleteId(null)


  const handleChangeDictionary = ({ id, ...updates }) => {
    services
      .updateDictionary(id, updates)
      .then(({ dictionary }) => updateDictionary(id, dictionary))
  }

  const handleClickAddWord = e => {
    e.preventDefault()

    if (!newWordValue) return

    services
      .createWord({ value: newWordValue, dictionary: currentDictionary.id })
      .then(({ word }) => {
        setWord(word)
        setNewWordValue('')
        setCurrentLetter(word.value.toLowerCase()[0])
      })
  }

  const handleClickLetter = letter => {
    const index = words.findIndex(w => w.value.toLowerCase()[0] === letter)+1

    setCurrentLetter(letter)

    if (!index) return
    setActivePageIndex(Math.floor(index / nbItemsPerPage) + 1)
  }

  const handleChangeWord = ({ id, ...updates }) => {
    services
      .updateWord(id, updates)
      .then(({ word }) => updateWord(id, word))
  }

  const handleClickDeleteWord = word => {
    services
      .deleteWord(word.id)
      .then(() => deleteWord(word.id))
  }

  let _projects = projects
  if (Object.keys(query).length)
    _projects = projects.filter(project =>
      Object.keys(query).every(k =>
        project[k] && (project[k] + '').toLowerCase().includes(query[k].toLowerCase())
      )
    )
  const offset = (activePageIndex-1) * nbItemsPerPage

  return (
    <StyledPage>
      <H1>Dictionnaires Métiers</H1>
      <StyledSection>
        <StyledColumns nb={4}>
          {
            onLoadingProjects
            ? (
              <StyledLoading>
                <SpinnerAnimatedIcon />&nbsp;
                Chargement ...
              </StyledLoading>
            ) : (
              <>
                <QuerySearchInput onChange={setQuery} help={false} />
                <Projects>
                  {
                    projects.length
                    ?
                      <Ul>
                        {_projects
                          .map(project =>
                          <ProjectLi
                            key={`project-${project.id}`}
                            project={project}
                            dictionaries={
                              dictionaries.filter(dictionary => dictionary.projects.includes(project.id))
                            }
                            onClickDictionary={handleClickDictionary}
                            onClickAddDictionary={handleClickAddDictionary}
                            onClickDeleteDictionary={handleClickDeleteDictionary}
                          />
                        )}
                      </Ul>
                    :
                      <P>
                        Il semble que vous n'ayez aucun projet.<br />
                        Vous devez <Link to="/manage/projects">créer un projet</Link> avant d'y ajouter des dictionnaires.
                      </P>
                  }
                </Projects>
              </>
            )
          }
        </StyledColumns>
        <StyledColumns nb={8}>
          <StyledOptions>
            {
              currentDictionary
              ? <DictionaryForm dictionary={currentDictionary} onChange={handleChangeDictionary} />
              : <P>Sélectionner un dictionnaire afin de lui ajouter des mots</P>
            }
            <Form onSubmit={handleClickAddWord}>
              <TextInput
                name="name"
                value={newWordValue}
                placeholder="Ajouter un terme"
                buttonIcon={AddCircleFilledIcon}
                onChange={setNewWordValue}
                onClick={handleClickAddWord}
                disabled={!currentDictionary}
              />
            </Form>
          </StyledOptions>
          {
            onLoadingDictionary
            ? (
              <StyledLoading>
                <SpinnerAnimatedIcon />&nbsp;
                Chargement ...
              </StyledLoading>
            ) : (
              <div>
                <Alphabet
                  currentLetter={currentLetter}
                  enabledLetters={
                    'abcdefghijklmnopqrstuvwxyz'.split('').filter(l =>
                      !!words.find(w => w.value.toLowerCase()[0] === l)
                    )
                  }
                  onClick={handleClickLetter}
                />
                <StyledWordsUl>
                  {
                    words
                      .filter(v => currentLetter === null || v.value.toLowerCase()[0] === currentLetter)
                      .sort((a, b) => a.value.toLowerCase() < b.value.toLowerCase() ? -1 : 1)
                      .slice(offset, offset + nbItemsPerPage)
                      .map(word =>
                        <WordLi
                          key={`word-${word.id}`}
                          word={word}
                          onChange={handleChangeWord}
                          onClickDelete={() => handleClickDeleteWord(word)}
                        />
                      )
                  }
                </StyledWordsUl>
                <Pagination
                  nbItemsPerPage={nbItemsPerPage}
                  nbPagesDisplayed={5}
                  activePageIndex={activePageIndex}
                  totalItems={words.filter(
                    v => currentLetter === null || v.value.toLowerCase()[0] === currentLetter).length
                  }
                  onChange={i => setActivePageIndex(i)}
                />
              </div>
            )
          }
        </StyledColumns>
      </StyledSection>

      {toDeleteId &&
        <ConfirmationModal isOpen danger
          title="Êtes-vous sûr de vouloir faire ça ?"
          description="Supprimer un dictionnaire. Cette action est irreversible."
          onConfirm={() => handleConfirmDelete(toDeleteId)}
          onCancel={() => handleCancelDelete()}
        />
      }

    </StyledPage>
  )
}
