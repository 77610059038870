import React, { useState } from 'react'
import styled from 'styled-components'

import {
  Container,
  H1,
  H2,
  P,
  Form,
  FormGroup,
  TextInput,
  Select,
  Option,
  Checkbox,
  Radio,
  Button,
  Icon,
  EditIcon,
  WarningIcon,
  SettingsIcon,
  GearIcon,
} from '../components/design-system'

const StyledH1 = styled(H1)`
  margin: 2rem 0px;
`

const StyledH2 = styled(H2)`
  font-family: Blinker;
  font-size: 1.5625rem;
  letter-spacing: 0;
  margin: 1.875rem 0;
  font-weight: 700;

  ${Icon} {
    margin-right: .625rem;
  }
`

const Description = styled.p`
  margin-bottom: .625rem;
`

const Warning = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.dangerRed};

  > ${Icon} {
    height: 2.5rem;
    width: auto;
    color: ${({ theme }) => theme.dangerRed};
  }
  > strong {
    display: block;
    font-size: 1.5rem;
  }
  > p {
    width: 75%;
    margin: 1rem auto 0 auto;
  }
`

const SubForm = styled(Form)`
  margin-bottom: 1.75rem;
`

export default () => {
  const [path1, setPath1] = useState(null)
  const [path2, setPath2] = useState(null)

  return (
    <Container>
      <StyledH1>Paramètres avancés</StyledH1>
      <Warning>
        <WarningIcon />
        <strong>Attention</strong>
        <P>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </P>
      </Warning>
      <div>
        <StyledH2>
          <SettingsIcon /> Chemin d'accès :
        </StyledH2>
        <SubForm onSubmit={e => e.preventDefault()}>
          <FormGroup>
            <TextInput label='Chemin des répertoires' buttonIcon={EditIcon} value={path1} onChange={setPath1} />
          </FormGroup>
          <Button type='submit' primary>Valider</Button>
        </SubForm>
        <SubForm onSubmit={e => e.preventDefault()}>
          <FormGroup>
            <TextInput label='Accès base de données' buttonIcon={EditIcon} value={path2} onChange={setPath2} />
          </FormGroup>
          <Button type='submit' primary>Valider</Button>
        </SubForm>
      </div>
      <div>
        <StyledH2><GearIcon /> Paramètres de l'outil :</StyledH2>
        <Form onSubmit={e => e.preventDefault()}>
          <Description><b>Paramètre 1 :</b> Permet de (description du paramètre)</Description>
          <FormGroup>
            <Radio name='param-1' label='Activé' />
            <Radio name='param-1' label='Désactivé' />
          </FormGroup>
          <Description><b>Paramètre 2 :</b> Permet de (description du paramètre)</Description>
          <FormGroup>
            <Checkbox label='Opérande 1' />
            <Checkbox label='Opérande 2' />
            <Checkbox label='Opérande 3' />
          </FormGroup>
          <Description><b>Paramètre 3 :</b> Permet de (description du paramètre)</Description>
          <FormGroup>
            <Select placeholder='Sélectionner une option' style={{ minWidth: '50%' }}>
              <Option value="1">Option 1</Option>
              <Option value="2">Option 2</Option>
              <Option value="3">Option 3</Option>
            </Select>
          </FormGroup>
          <Button type='submit' primary>Valider les paramètres</Button>
        </Form>
      </div>
    </Container>
  )
}
