import axios from 'axios'

export const fetchDocument = id =>
  axios.get(`/api/documents/${id}`).then(({ data }) => data)

export const fetchAllDocuments = params =>
  axios.get('/api/documents', { params }).then(({ data }) => data)

export const fetchConvertingDocuments = () =>
  fetchAllDocuments({ state: 'converting' })

export const fetchDetectingDocuments = () =>
  fetchAllDocuments({ state: 'detecting' })

export const fetchOCRizingDocuments = () =>
  fetchAllDocuments({ state: 'ocrizing' })

export const fetchOCRizedDocuments = () =>
  fetchAllDocuments({ state: 'done' })

export const uploadFile = (
  file,
  project,
  {
    onUploadProgress = () => null,
    cancelToken,
    ...config
  }
) => {
  const formData = new FormData()

  formData.append('files[]', file)
  formData.append('project', project)

  return axios.post('/api/documents', formData, {
    cancelToken: cancelToken.token,
    headers: { 'content-type': 'multipart/form-data' },
    onUploadProgress: e => onUploadProgress(Math.round((e.loaded * 100) / e.total), e),
    ...config
  }).then(({ data }) => data)
}

export const updateDocument = (id, body) =>
  axios.put(`/api/documents/${id}`, body).then(({ data }) => data)

export const deleteDocument = id =>
  axios.delete(`/api/documents/${id}`).then(({ data }) => data)

export default {
  fetchDocument,
  fetchAllDocuments,
  fetchConvertingDocuments,
  fetchDetectingDocuments,
  fetchOCRizingDocuments,
  uploadFile,
  updateDocument,
  deleteDocument,
}
