import axios from 'axios'

// export const fetchProfileNodeDetections = (params, options = {}) =>
//   axios.get('/api/profileNodeDetections', {
//     params,
//     ...options
//   }).then(({ data }) => data)

export const upsertProfileNodeDetections = body =>
  axios.post('/api/profileNodeDetections', body).then(({ data }) => data)
