import React, { useEffect, useRef, useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import {
  Icon,
  SpinnerAnimatedIcon,
} from '../components'

import FabricContext from '../contexts/FabricContext'
import FabricCanvas from './FabricCanvas'

const ResponsiveWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  overflow: hidden;

  width: auto;
  height: auto;

  ${({ orientation, displayPanel }) => `
    top: ${orientation === 'vertical' ? '0' : '3rem'};
    left: ${
      displayPanel
      ? (orientation === 'vertical' ? '398px' : '350px')
      : (orientation === 'vertical' ? '3rem' : '0')
    };
  `}
`

const Loading = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, .05);

  ${Icon} {
    width: 3rem;
    height: 3rem;
  }
`

const PageInfo = styled.div`
  position: absolute;
  top: 2rem;
  left: 2rem;
  font-size: .875rem;

  padding: .75rem;
  color: ${({ theme }) => theme.white};
  background-color: rgba(0, 0, 0, .75);
  border-radius: 5px;
`

const Meta = styled.div`
  margin-top: .5rem;
`

const DocumentScore = styled.span`
  margin-right: .5rem;
  padding: .25rem;
  border-radius: 5px;
  font-weight: bold;
`
// retiré car faisait planter l'explorateur à la fermeture quand un document était ouvert -> background-color: ${({ theme, value }) => theme.ocrScoreColor(value) };

const OCRCanvas = ({
  loading,
  orientation,
  displayPanel,
  currentDocument,
  currentPage,
  ...props
}) => {
  const wrapper = useRef(null)
  const [{ canvas }] = useContext(FabricContext)

  const handleResize = () => {
    canvas.setDimensions({
      width: wrapper.current.offsetWidth,
      height: wrapper.current.offsetHeight,
    })
  }

  useEffect(() => {
    if (!canvas) return

    handleResize()
  }, [canvas, orientation, displayPanel])

  return (
    <ResponsiveWrapper
      ref={wrapper}
      orientation={orientation}
      displayPanel={displayPanel}
      {...props}
    >
      <FabricCanvas />
      {
        !displayPanel && currentDocument && currentPage &&
        <PageInfo>
          <strong>{currentDocument.name}</strong>
          <Meta>
            <DocumentScore value={currentDocument.ocrScore}>{currentDocument.ocrScore}%</DocumentScore>
            <span>{currentPage.ocrScore}% · Page {currentPage.index}</span>
          </Meta>
        </PageInfo>
      }
      {
        loading &&
        <Loading>
          <SpinnerAnimatedIcon />
        </Loading>
      }
    </ResponsiveWrapper>
  )
}


OCRCanvas.propTypes = {
  loading: PropTypes.bool.isRequired,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']).isRequired,
  displayPanel: PropTypes.bool.isRequired,
  currentDocument: PropTypes.object,
  currentPage: PropTypes.object,
}

OCRCanvas.defaultProps = {
  currentDocument: null,
  currentPage: null,
}

export default OCRCanvas
