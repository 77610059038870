import React, { useState, useContext, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import qs from 'qs'

import useList from '../hooks/useList'
import * as services from '../services'

import ProfileForm from '../modules/ProfileForm'

import {
  Section,
  Columns,
  P,
  H1,
  Select,
  Option,
  SearchInput,
  Table,
  THead,
  TBody,
  Tr,
  Th,
  Td,
  TdForm,
  EditIcon,
  CloseCircleFilledIcon,
  SpinnerAnimatedIcon,
  Pagination,
  BlankButton,
  ConfirmationModal,
} from '../components'

const StyledPage = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
`

const StyledSection = styled(Section)`
  padding: 0 4%;
  flex: 1;

  margin-bottom: 2rem;
`

const StyledColumns = styled(Columns)`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const StyledFilters = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`

const StyledSearchInput = styled(SearchInput)`
  width: 300px;
`

const StyledHelp = styled.span`
  display: block;
  margin-top: .5rem;
  font-size: .75rem;
  font-style: italic;
  color: ${({ theme }) => theme.alto};
`

const EmptyHelp = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 2rem 0;
`

const StyledTable = styled(Table)`
  width: 100%;
`

const StyledTr = styled(Tr)`
  ${({ highlighted }) => highlighted && `
    font-weight: bold;
  `}
`

const LTh = styled(Th)`
  width: 100%;
`

const EditButton = styled(BlankButton)`
  color: ${({ theme }) => theme.primaryColor};
`

const DeleteButton = styled(BlankButton)`
  color: ${({ theme }) => theme.dangerRed};
`

const attrToFr = {
  'name': 'nom',
  'format': 'type',
}

const frToAttr = {
  'nom': 'name',
  'type': 'format',
}

const StyledLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export default () => {
  const location = useLocation()
  const [onLoading, setOnLoading] = useState(true)
  const [query, setQuery] = useState(qs.parse(location.search.replace(/^\?/, '')))
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState(null)
  const [activePageIndex, setActivePageIndex] = useState(1)
  const [nbItemsPerPage, setNbItemsPerPage] = useState(20)

  const formRef = useRef(null)
  const [toUpdate, setToUpdate] = useState(null)
  const [toDeleteId, setToDeleteId] = useState(null)
  const [
    { items:profiles },
    {
      setItem:setProfile,
      setItems:setProfiles,
      updateItem:updateProfile,
      deleteItem:deleteProfile,
    }
  ] = useList()

  const toggleOrder = by => {
    if (orderBy === by) return setOrder(order === 'asc' ? 'desc' : 'asc')

    setOrderBy(by)
    setOrder('desc')
  }

  const queryToSearchValue = () =>
    Object.keys(query).map(k => `${attrToFr[k]}:${query[k]}`).join(' ')

  const searchValueToQuery = value => {
    const query = {}

    value.split(' ').forEach(q => {
      let [k, v] = q.split(':')
      if (!k) return

      [k, v] = [frToAttr[k] || 'name', v || k]
      query[k] = v || ''
    })

    return query
  }

  const handleNameUpdate = (id, name) => {
    services
      .updateProfile(id, { name })
      .then(({ profile }) => updateProfile(profile.id, profile))
  }

  const handleClickEdit = profile => {
    if (toUpdate && toUpdate.id === profile.id) return

    setToUpdate(profile)

    services
      .fetchProfile(profile.id)
      .then(({ profile }) => {
        updateProfile(profile.id, profile)
        setToUpdate(profile)
      })
  }

  const handleCancelEdit = () =>
    setToUpdate(null)

  const handleClickDelete = ({ id }) => {
    setToDeleteId(id)
  }

  const handleConfirmDelete = id => {
    services.deleteProfile(id).then(() => deleteProfile(id))
    setToDeleteId(null)
  }

  const handleCancelDelete = () =>
    setToDeleteId()

  const handleSubmit = ({ id, ...updates }) => {
    if (id)
      services
        .updateProfile(id, updates)
        .then(({ profile }) => {
          updateProfile(profile.id, profile)
          setToUpdate(profile)
        })
    else
      services
        .createProfile(updates)
        .then(({ profile }) => {
          setProfile(profile)
          setToUpdate(profile)
        })
  }

  useEffect(() => {
    services
      .fetchAllProfiles()
      .then(({ profiles }) => {
        setOnLoading(false)
        setProfiles(profiles)
      })
  }, [])

  let _profiles = profiles
  if (Object.keys(query).length)
    _profiles = profiles.filter(profile =>
      Object.keys(query).every(k =>
        profile[k] && (profile[k] + '').toLowerCase().includes(query[k].toLowerCase())
      )
    )

  const offset = (activePageIndex-1) * nbItemsPerPage

  return (
    <>
      <StyledPage>
        <H1 style={{ margin: '2rem 0' }}>Gérer les profils</H1>
        <StyledSection>
          <StyledColumns nb={7}>
            <StyledFilters>
              <div>
                <StyledSearchInput
                  placeholder="Rechercher"
                  defaultValue={queryToSearchValue()}
                  onChange={v => setQuery(searchValueToQuery(v))}
                />
                <StyledHelp>(nom:FR_143xx type:xml ...)</StyledHelp>
              </div>
              <Select onChange={v => setNbItemsPerPage(v)}>
                <Option value={20} selected={nbItemsPerPage === 20}>Afficher 20 lignes</Option>
                <Option value={50} selected={nbItemsPerPage === 50}>Afficher 50 lignes</Option>
                <Option value={100} selected={nbItemsPerPage === 100}>Afficher 100 lignes</Option>
              </Select>
            </StyledFilters>
            {
              onLoading
              ? (
                <StyledLoading>
                  <SpinnerAnimatedIcon />&nbsp;
                  Chargement ...
                </StyledLoading>
              ) : (
                <StyledTable>
                  <THead>
                    <Tr>
                      <LTh
                        onClickOrder={() => toggleOrder('name')}
                        order={orderBy === 'name' ? order : null}
                      >
                        Nom
                      </LTh>
                      <Th
                        onClickOrder={() => toggleOrder('format')}
                        order={orderBy === 'format' ? order : null}
                      >
                        Type
                      </Th>
                      <Th><EditIcon /></Th>
                      <Th><CloseCircleFilledIcon /></Th>
                    </Tr>
                  </THead>
                  <TBody>
                    {_profiles.slice(offset, offset + nbItemsPerPage).sort(
                      (a, b) => (
                        orderBy
                        ? (order === 'asc'
                          ? (a[orderBy] > b[orderBy] ? -1 : 1)
                          : (a[orderBy] < b[orderBy] ? -1 : 1))
                        : 0
                      )).map(profile => (
                      <StyledTr key={profile.id} highlighted={toUpdate && profile.id === toUpdate.id}>
                        <TdForm value={profile.name} onSubmit={value => handleNameUpdate(profile.id, value)} />
                        <Td>{profile.format}</Td>
                        <Td>
                          <EditButton onClick={e => handleClickEdit(profile)}>
                            <EditIcon />
                          </EditButton>
                        </Td>
                        <Td>
                          <DeleteButton onClick={e => handleClickDelete(profile)}>
                            <CloseCircleFilledIcon />
                          </DeleteButton>
                        </Td>
                      </StyledTr>
                    ))}
                  </TBody>
                </StyledTable>
              )
            }
            {
              (!onLoading && !profiles.length) &&
              <EmptyHelp>
                <P>
                  Il semble que vous n'ayez aucun profil.<br />
                  Vous pouvez <EditButton primary onClick={() => formRef.current.focus()}>créer un profil</EditButton>.
                </P>
              </EmptyHelp>
            }
            <Pagination
              nbItemsPerPage={nbItemsPerPage}
              nbPagesDisplayed={5}
              activePageIndex={activePageIndex}
              totalItems={_profiles.length}
              onChange={i => setActivePageIndex(i)}
            />
          </StyledColumns>
          <StyledColumns nb={5} style={{ paddingLeft: '4%', borderLeft: '1px solid #d8d8d8' }}>
            <ProfileForm
              ref={formRef}
              profile={toUpdate || { nodes: [] }}
              onSubmit={handleSubmit}
              onCancel={handleCancelEdit}
            />
          </StyledColumns>
        </StyledSection>
      </StyledPage>

      {toDeleteId &&
        <ConfirmationModal isOpen danger
          title="Êtes-vous sûr de vouloir faire ça ?"
          description="Supprimer un profil. Cette action est irreversible."
          onConfirm={() => handleConfirmDelete(toDeleteId)}
          onCancel={() => handleCancelDelete()}
        />
      }

    </>
  )
}
